import React from 'react'
import { VStack, Heading, Box } from '@chakra-ui/react'
import ServicesHeader from '../components/templates/Services/ServicesHeader'
import image from '../assets/Pictures/Packages 1.jpg'
import PackagesLanding from '../components/templates/Services/PackagesLanding'
import PackagesInfo from '../components/templates/Services/PackagesInfo'
import ChangePrices from '../components/elements/ChangePrices'
import PackagesExtra from '../components/templates/Services/PackagesExtra'

const Packages = () => {
  return (
    <>
      <VStack overflowX={'hidden'} >
        <ServicesHeader image={image} active="packages" />
        <PackagesLanding title="Packages"
        description="We offer a wide range of travel packages that cater to different travel styles 
        and preferences. Our packages are designed to make your travel experience hassle-free 
        and unforgettable, whether you're traveling for leisure, business, or education.
        We offer packages for domestic and international destinations, including popular tourist spots, 
        off-the-beaten-path destinations, and adventure travel."
         />
         <Heading 
            fontFamily={'Merriweather'}
            color="black"
            h="60px"
            py="0.5em"
            pt={'1em'}
            align={'center'}
            fontSize={{base: 'xl',md: 'xl',lg: '32px'}} 
            fontWeight={600}>
            Popular Packages 
          </Heading>  
         <PackagesInfo />
          
          <Box w={'100%'}>
            <ChangePrices color2="#FFE071" 
            color="#1E2756" 
            description={`*Please Note that the Prices may vary according to the current fair and conditions.\n
            *You can learn more about the current prices and fares by Contacting Us.`}
            link="/contact" />
            <PackagesExtra />
         </Box>
      </VStack>
    </>
  )
}

export default Packages