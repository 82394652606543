import React from 'react'
import {HStack, Image} from '@chakra-ui/react'
import logo from '../../assets/Logo/Logo.png';

const Logo = () => {
  return (
    <HStack bg="white" 
    px={{ base: 1, md: 6, lg: 7 }}
    py={{ base: 2, md: 3, lg: 4 }}>
        <Image  src={logo}
                alt='SAS Travels Logo'
                h={{ base: '40px', md: '50px', lg: '80px' }}
            />
    </HStack>
  )
}

export default Logo