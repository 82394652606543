import {  Heading, Flex, Link, HStack, VStack, Stack, Show } from '@chakra-ui/react';
import { Divider } from '@chakra-ui/react';
import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedin, FaTiktok, FaWhatsapp } from "react-icons/fa"
import Logo from '../elements/Logo'


const Footers = () => {
  const Year = new Date().getFullYear(); 
  const WhatsappButtonClicked = (event) => {

    const whatsappMessage = `Hey, My name is ________.
I saw your website and wanted to get in touch with you about _______________.`;
    const whatsappUrl = `https://wa.me/+971564117736/?text=${encodeURIComponent(whatsappMessage)}`;
    window.open(whatsappUrl, "_blank");
    }

  return (
    <Flex 
    wrap="wrap"
    w="100%"
    as="footer" 
    color='white'>
      {/* <Container maxW="container.2xl"> */}
        <Stack bg="#1E2756"  px={{base: '1em', md: '2em', lg: '4em'}}
             py={{base: '1em', md: '1em', lg: '3em'}} 
             w="100%" direction={[ 'column','column', 'row']}>

          <VStack w={{base: '100%', md: '100%', lg: '35%'}} py="0.5em">
            <Logo />
            <Heading 
              fontFamily={'Merriweather'}
              color="white"
              h="65px"
              py="0.5em"
              align={'center'}
              fontSize={{base: 'sm',md: 'md',lg: 'lg'}} 
              fontWeight={400}>
            We provide comprehensive travel solutions for leisure, group, corporate or educational purpose.
          </Heading>  
          </VStack>
          
          <Show breakpoint='(max-width: 730px)'
          >
            <Divider  />
          </Show>


          <HStack w={{base: '100%',md: '100%',lg: '35%'}} gap="45px" py="0.5em">
            
          <VStack gap="5px"
                 alignItems={'flex-start'} 
                  px={'1em'}
                    w={{base: '50%', md: '50%', lg: '50%'}}
                    h="200px">
              <Link href="/">     
                <Heading size='md' >Our Company</Heading>
              </Link>
              <Link href="/about">
                <span>
                  About Us
                </span>
              </Link>
              <Link href="/contact">
                <span>
                  Contact
                </span>
              </Link>
              <Link href="/privacyandpolicy">
                <span>
                  Privacy Policy
                </span>
              </Link>
        
            </VStack>
            <VStack gap="5px" 
                    alignItems={'flex-start'} 
                    px={'1em'}
                    w={{base: '50%', md: '50%', lg: '50%'}} 
                    h="200px" >
              <Link href="/services">
                <Heading color="white" size='md'>Services</Heading>
              </Link>
              <Link href="/services/packages">
                <span>
                  Packages
                </span>
              </Link>
              <Link href="/services/flights">
                <span>
                  Flights
                </span>
              </Link>
              <Link href="/services/hotels">
                <span>
                  Hotel
                </span>
              </Link>
              <Link href="/services/visa">
                <span>
                  Visa
                </span>
              </Link>
              <Link href="/services/attractions">
                <span>
                  Attractions
                </span>
              </Link>
            </VStack>

          </HStack>

          <Show breakpoint='(max-width: 730px)'
          >
            <Divider  />
          </Show>
          
          <HStack  justifyContent={'center'} 
                  alignItems={'flex-start'}
                  w={{base: '100%',md: '100%',lg: '30%'}}
                  gap="1em" p="1em">
              <Link 
              href="https://www.facebook.com/profile.php?id=100087945985632">
                <span size='md' class="inline-block h-12 w-12 ring-white">
                  <FaFacebookF  style={{fontSize: '30px'}}/>
                </span>
              </Link>
              <Link 
              href="https://www.instagram.com/sas_up1/?hl=en">
                <span size='md' class="inline-block h-12 w-12 ring-white">
                  <FaInstagram  style={{fontSize: '30px'}}/>
                </span>
              </Link>
              <Link 
              href="https://www.tiktok.com/@sastravels1?lang=en">
                <span size='md' class="inline-block h-12 w-12 ring-white">
                  <FaTiktok  style={{fontSize: '30px'}}/>
                </span>
              </Link>
              <Link 
              onClick={WhatsappButtonClicked}>
                <span size='md' class="inline-block h-12 w-12 ring-white">
                  <FaWhatsapp style={{fontSize: '30px'}}/>
                </span>
              </Link>
              <Link 
              href="https://www.linkedin.com">
                <span size='md' class="inline-block h-12 w-12 ring-white">
                  <FaLinkedin style={{fontSize: '30px'}}/>
                </span>
              </Link>
          </HStack>

        </Stack>

        <Divider/>

        <HStack w="100%" bg="#1E2756" p="1em">
          <HStack w={{base: '100%',md: '100%',lg: '100%'}} >
            <Heading fontSize={{base: 'xs',md: 'xs',lg: 'sm'}} pl="2em"> Copyright &copy;  {Year} SAS Travels and Tourism L.L.C</Heading>
          </HStack>

        </HStack>

    </Flex>
  )
}

export default Footers