import React from 'react'
import { VStack, Heading, Box} from '@chakra-ui/react';


const ServicesDescription = (props) => {
    const { title, description } = props;
  return (
    <>
    <VStack
    // mx="1em"
    p={{base: '1em', md: '1em', lg:'0.5em'}}
    background="transparent"
    alignItems="left"
    w={{base: '180px', md: '240px', lg:'350px'}}
    // h={[230, 230, 230]}
    overflow="hidden">
        <Box h={{base: '50px',md: '60px',lg: '80px'}}  >
            <Heading fontSize={{base: 'md',md: 'lg',lg: '28px'}} 
            fontFamily={'Merriweather'} 
            align="left"
            fontWeight={600} pt={{base: '1em',md: '1em',lg: '0.7em'}} color={'#1E2756'}>
                {title}
            </Heading>
        </Box>
        <Box pt={'0.5em'}>
            <Heading 
            fontSize={{base: 'sm',md: 'md',lg: '18px'}} 
            fontFamily={'Merriweather'} align="left" 
            fontWeight={500} >
                {description}
            </Heading>
        </Box>
        
    </VStack>
</>
  )
}

export default ServicesDescription