import React from "react";
import {
  Stack,
  VStack,
  Box,
  Heading,
  Accordion,
} from "@chakra-ui/react";
import "../../../theme/index.css"
import ServicesImage from "../../modules/ServicesImage";
import AccordianCard from "../../modules/AccordianCard";
import services1 from "../../../assets/Pictures/Services/Services-1.jpg";
import services2 from "../../../assets/Pictures/Services/Services-2.jpg";
import services3 from "../../../assets/Pictures/Services/Services-3.jpg";
import services4 from "../../../assets/Pictures/Services/Services-4.jpg";
import services5 from "../../../assets/Pictures/Services/Services-5.jpg";
import services6 from "../../../assets/Pictures/Services/Services-6.jpg";
import services7 from "../../../assets/Pictures/Services/Services-7.jpg";
import services8 from "../../../assets/Pictures/Services/Services-8.jpg";
import services9 from "../../../assets/Pictures/Services/Services-9.jpg";
import services10 from "../../../assets/Pictures/Services/Services-10.jpg";
import services11 from "../../../assets/Pictures/Services/Services-11.jpg";
import services12 from "../../../assets/Pictures/Services/Services-12.jpg";

const ServicesInfo = () => {
  return (
    <>
      <Box  w={'100%'} >
        <Stack  bg="#FFC966" 
                p={"3em"} 
                align="center" 
                w={'100%'} direction={["column", "row"]} 
                mt={'-6.2em'} pt={'5em'} >
            <VStack w={{base: '100%', md: '50%', lg: '50%'}} p="1em" align={'left'}>
              <Heading
                fontSize={{ base: "lg", md: "2xl", lg: "42" }}
                fontWeight={600}
                fontFamily={'Merriweather'}
              >
                Travel Packages
              </Heading>
              <Accordion>
                <AccordianCard 
                    title="Umrah Packages" 
                    description="Our Umrah packages take care of all the arrangements for your pilgrimage to Mecca, 
                    so you can focus on your spiritual journey."
                    color="#1E2756"
                />
                <AccordianCard 
                    title="Holiday Packages" 
                    description="We offer holiday packages to popular destinations, 
                    so you can sit back and relax while we take care of all the details."
                    color="#1E2756"
                />
                <AccordianCard 
                    title="World Tour Packages" 
                    description="For those seeking more extensive travel, 
                                we offer world tour packages to explore the world's best destinations."
                    color="#1E2756"
                />
                </Accordion>
            </VStack>
            <VStack w={{base: '100%', md: '50%', lg: '50%'}}>
                <ServicesImage image1={services1} image2={services2} 
                image3={services3} image4={services4}/>
            </VStack>
        </Stack>
        <Stack  bg="#1E2756" 
                p={"3em"} align="center" 
                w={'100%'} direction={["column", "row"]} 
                pt={'5em'} >
            <VStack w={{base: '100%', md: '50%', lg: '50%'}}>
                <ServicesImage image1={services5} image2={services6} 
                image3={services7} image4={services8} />
            </VStack>
            <VStack w={{base: '100%', md: '50%', lg: '50%'}}
             p="1em" align={'left'}>
              <Heading
                fontSize={{ base: "lg", md: "2xl", lg: "42" }}
                fontWeight={600}
                fontFamily={'Merriweather'}
                color={'#FFC966'}
              >
                Travel Services
              </Heading>
              <Accordion>
              <AccordianCard 
                    title="Flights" 
                    description="We offer a range of flight services 
                    to make your travel experience hassle-free."
                    color="#FFC966"
                />
                <AccordianCard 
                    title="City Tours" 
                    description="We offer a variety of city tours to showcase the best of the UAE's landmarks and attractions."
                    color="#FFC966"
                />
                <AccordianCard 
                    title="Hotel Bookings" 
                    description="We make it simple to book your accommodations for your travels."
                    color="#FFC966"
                />
                <AccordianCard 
                    title="Cruise Booking" 
                    description="Explore the open seas with our cruise booking services."
                    color="#FFC966"
                />
                <AccordianCard 
                    title="MICE" 
                    description="We offer meeting, incentive, conference, and event planning services for businesses."
                    color="#FFC966"
                />
              </Accordion>
                           
            </VStack>
            
        </Stack>
        <Stack  bg="#FFC966" 
                p={"3em"} align="center" 
                w={'100%'} direction={["column", "row"]} pt={'5em'} >
            <VStack w={{base: '100%', md: '50%', lg: '50%'}}
             p="1em" align={'left'}>
              <Heading
                fontSize={{ base: "lg", md: "2xl", lg: "42" }}
                fontWeight={600}
                fontFamily={'Merriweather'}
              >
                Travel Essentials
              </Heading>
              <Accordion>
              <AccordianCard 
                    title="U.A.E Visit Visa" 
                    description="Our UAE visit visa services make it easy for you to obtain a visa to explore the United Arab Emirates."
                    color="#1E2756"
                />
              <AccordianCard 
                    title="Travel Insurance" 
                    description="Our travel insurance provides peace of mind while you travel, so you can enjoy your trip worry-free."
                    color="#1E2756"
                />
                <AccordianCard 
                    title="Global Visa Assistance" 
                    description="Our visa assistance services can help with visa applications to make travel easier."
                    color="#1E2756"
                />
              </Accordion>
                           
            </VStack>
            <VStack w={{base: '100%', md: '50%', lg: '50%'}}>
                <ServicesImage image1={services9} image2={services10} 
                image3={services11} image4={services12} />
            </VStack>
        </Stack>   
      </Box>
      </>
  )
}

export default ServicesInfo