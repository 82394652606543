import React from 'react'
import packages from '../../../assets/Icons/Packages.png'
import flights from '../../../assets/Icons/Flights.png'
import visa from '../../../assets/Icons/Visa.png'
import hotels from '../../../assets/Icons/Hotel.png'
import attractions from '../../../assets/Icons/Attractions.png'
import ServicesBox from '../../modules/ServicesBox'

import { Box, Image, SimpleGrid} from '@chakra-ui/react'

const ServicesHeader = (props) => {
    
    const { image, active } = props;
    console.log({active})
  return (
    <>
        <Box w="100%" 
        mb={{base: '-1em',md: '-5em',lg: '-5em'}}
        >
            <Image
              objectFit="contain"
              src={image}
              alt="Banner"/>
        </Box>
        <SimpleGrid align={'center'} 
        pt={{base: '1em',md: '0',lg: '0'}}
        p={{base: '0',md: '1em',lg: '0'}} 
        columns={5} 
        spacing={[1,2, 5, 5]} >
            <ServicesBox 
                    flag={active === "packages" ? "active" : "null" }
                    image={packages}
                    title="Packages"
                    link="/services/packages"/>
            <ServicesBox 
                    flag={active === "flights" ? "active" : "null" }
                    image={flights}
                    title="Flights"
                    link="/services/flights"/>
            <ServicesBox 
                    flag={active === "hotels" ? "active" : "null" }
                    image={hotels}
                    title="Hotels"
                    link="/services/hotels"/>
            <ServicesBox 
                    flag={active === "visa" ? "active" : "null" }
                    image={visa}
                    title="Visa"
                    link="/services/visa"/>
            <ServicesBox 
                    flag={active === "attractions" ? "active" : "null" }
                    image={attractions}
                    title="Attractions"
                    link="/services/attractions"/>
        </SimpleGrid>
    </>
    
  )
}

export default ServicesHeader