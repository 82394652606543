import React from 'react'
import { HStack, Heading, Link, Button, Box } from '@chakra-ui/react';

const ChangePrices = (props) => {
    const { link, description, color, color2 } = props;
  return (
    <Box
        p="1em"
        background={color}
        gap="10px"
        alignItems="left"
        pl={3}
        w={'100%'}
        overflow="hidden"
        >
        <HStack>
            <Heading fontSize={{base: 'sm',md: 'md',lg: '18px'}} 
            fontFamily={'Merriweather'}
            noOfLines={5}
            color={color2}
            fontWeight={400} pl={'1.5em'}>
                {description}
            </Heading>
            <Box py="1em" px="1em" align={'right'} w="100%" >
                        <Link href={link}>
                            <Button
                                w="100px"
                                h="50px"
                                variant="solid"
                                bg={color2}
                                color={color} 
                                fontFamily={'Merriweather'}    
                            >
                                Contact Us
                            </Button>
                        </Link>
                    </Box>
        </HStack>
    </Box>
  )
}

export default ChangePrices