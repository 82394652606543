import React from "react";
import {
  Center,
  Image,
  Stack,
  VStack,
  Box,
  Link,
  Heading,
  Button,
  Accordion,
} from "@chakra-ui/react";
import image from '../../../assets/Pictures/Homepage/Homepage-Offer.jpg'
import "../../../theme/index.css"
import AccordianCard from "../../modules/AccordianCard";


const HomeOffers = () => {
  return (
    <Center bg="#FFC966">
      <VStack>
        <Stack p={{ base: "1em", md: "2em", lg: "3em" }} align="center" direction={["column", "row"]}>
            <VStack w={{ base: "100%", md: "50%", lg: "50%" }}>
            <Box>
                <Box className="ribbon" w={'10em'} alignItems={'center'}>
                    <Box className="ribbon__side ribbon__side--l"></Box>
                    <Box className="ribbon__triangle ribbon__triangle--l"></Box>
                    <Box className="ribbon__triangle ribbon__triangle--r"></Box>
                    <Box className="ribbon__side ribbon__side--r"></Box>
                    <Box className="ribbon__content" align={'center'} w="100%">
                      <Heading 
                        fontFamily={'Merriweather'}
                        py={'0.5em'}
                        color={'#ffffff'}
                        fontSize={{base: 'sm',md: 'md',lg: '18px'}} 
                        fontWeight={400}>
                        Aed 2100/-
                      </Heading>
                    </Box>
                </Box>
                <Image
                  objectFit="contain"
                  borderRadius="md"
                  src={image}
                  alt="Offer"/>
                  
                  
            </Box>
            </VStack>
            <VStack w={{ base: "100%", md: "50%", lg: "50%" }} p="1em" align={'left'}>
              <Heading
                fontSize={{ base: "lg", md: "xl", lg: "42" }}
                fontWeight={600}
                fontFamily={'Merriweather'}
              >
                Specail Offers and Discount
              </Heading>
              <Heading
                fontSize={{ base: "lg", md: "xl", lg: "32" }}
                fontWeight={600}
                fontFamily={'Merriweather'}
                ml={5}
                color="#054457"
              >
                Explore UAE Package
              </Heading>
              
              <Accordion>
                <AccordianCard 
                    title="Plan" 
                    description="The Plan Includes 
                    30 days 0f Travel Visa  + Insurance + UAE Excursions + 3 Star Hotel Accommodations for 03 Nights and 04 Days
                    with Buffer Breakfast and rest amenities including sharing transfer in all local activities."
                    color="#1E2756"
                />
                <AccordianCard 
                    title="More Info" 
                    description="All your Tours and Excursions are by A/c Vehicle.
                    English Speaking tour help desk/drivers.
                    All sightseeing and entrance fees are as per itinerary."
                    color="#1E2756"
                />
              </Accordion>
              
              <Box align={'right'} pt={'0.3em'}>
                <Link href="/services/packages">
                  <Button
                  bg = "#1E2756"
                  color="white"
                  size='md'
                  borderRadius={20}
                  variant='solid'
                  fontSize={'18px'}>
                    Learn More
                  </Button>
                </Link>
              </Box>
              
            </VStack>
        </Stack>
      </VStack>
  </Center>
  )
}

export default HomeOffers