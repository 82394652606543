import React from 'react'
import {
    FormControl,
    FormLabel, VStack, SimpleGrid,
    // FormErrorMessage,
    // FormHelperText,
    Input,
    Select, NumberInput,
    NumberInputField, NumberInputStepper,
    NumberIncrementStepper, NumberDecrementStepper, HStack, Button
  } from '@chakra-ui/react'
  import countries from 'countries-list';
  
  
const HotelFormCard = () => {
    const state = {
        button: 0
      };

      const options = Object.values(countries.countries).map(country => country.name);


    const EmailButtonClicked = (formValues) => {
        const { city, nationality, checkInDate, checkOutDate, numberOfGuests } = formValues;
      
        const emailSubject = 'Inquiry about Hotel room in ' + city;
        const emailBody = `
          City: ${city}
          Nationality: ${nationality}
          Check-in Date: ${checkInDate}
          Check-out Date: ${checkOutDate}
          Number of Guests: ${numberOfGuests}
        `;
      
        const mailtoUrl = `mailto:Info@sas-travels.com?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
        window.open(mailtoUrl, "_blank");
      }

      const WhatsappButtonClicked = (formValues) => {
        const { city, nationality, checkInDate, checkOutDate, numberOfGuests } = formValues;
      
        const whatsappMessage = `Hey, My name is ________.
        I saw your website and wanted to get in touch with you for an 
        Inquiry about a Hotel room in ${city}
        Nationality: ${nationality}:
        Check-in Date: ${checkInDate}
        Check-out Date: ${checkOutDate}
        Number of Guests: ${numberOfGuests}`;
      
        const whatsappUrl = `https://wa.me/+971564117736/?text=${encodeURIComponent(whatsappMessage)}`;
        window.open(whatsappUrl, "_blank");
      }
      
      
      const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const formValues = Object.fromEntries(formData.entries());
        
        console.log(formValues)
        
        if (state.button === 1) {
            EmailButtonClicked(formValues);
        } else  if (state.button === 2) {
            WhatsappButtonClicked(formValues);
        }
      };
      
      

  return (
    <FormControl bg="#FEFEFF" w={'100%'}>
        <form onSubmit={handleSubmit}>
            <SimpleGrid  
            w={'100%'} 
            py={'1.2em'} 
            justify={'center'} 
            px={'0.5em'} 
            gap={'2em'}
            columns={[1, 2, 2, 6]} 
            spacing={[3, 5, 5 ,5]} 
            >
            <VStack>
                <FormLabel color="#1E2756" >City</FormLabel>
                <Select placeholder='Select city' 
                variant='flushed' size='md' 
                name="city" isRequired >
                    <option>Dubai, United Arab Emirates</option>
                    <option>Bangkok, Thailand</option>
                    <option>Paris, France</option>
                    <option>London, United Kingdom</option>
                    <option>Singapore</option>
                    <option>Kuala Lumpur, Malaysia</option>
                    <option>Istanbul, Turkey </option>
                    <option>Tokyo, Japan</option>
                    <option>Jeddah, Saudi Arabia</option>
                    <option>Delhi, India</option>
                    <option>Mumbai, India</option>
                    <option>Others</option>
                </Select>
            </VStack>
                    
            <VStack>
                <FormLabel color="#1E2756">Nationality</FormLabel>
                <Select placeholder='Select Nationality' name="nationality" 
                variant='flushed' size='md' 
                >
                  {options.map((item)=>{
                  return (
                    <option> {item}</option>
                  )
                    })}       
                </Select>
            </VStack>

            <VStack>
                <FormLabel color="#1E2756" >Check In Date</FormLabel>
                <Input 
                variant='flushed'
                placeholder="Select Date and Time"
                size="lg"
                name="checkInDate"
                type="date"
                />
            </VStack>
            
            <VStack>
                <FormLabel color="#1E2756" >Check Out Date</FormLabel>
                <Input 
                variant='flushed'
                size="lg"
                name="checkOutDate"
                placeholder="Select Date and Time"
                type="date"
                />
            </VStack>

            <VStack>
                <FormLabel color="#1E2756" >Number of Guests</FormLabel>
                <NumberInput size="lg"
                max={20} min={1} 
                name="numberOfGuests"
                variant='flushed'>
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
                </NumberInput>
            </VStack>

            <HStack justifyContent={'center'}>
            <Button 
                type="submit" 
                bg="#D45F46" 
                color="white"
                w="100px"
                h="50px"
                onClick={() => (state.button = 1)}
                variant="solid"
                name="buttonClicked"
                value="email"
                >
                Email
                </Button>
                <Button 
                id="whatsapp" 
                type="submit" 
                bg="#69B99D" 
                color="white"
                w="100px"
                h="50px"
                onClick={() => (state.button = 2)}
                variant="solid"
                name="buttonClicked"
                value="whatsapp"
                >
                Whatsapp
                </Button>

            </HStack>
            </SimpleGrid>
        </form>
        </FormControl>

  )
}

export default HotelFormCard