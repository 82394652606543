import React from 'react'
import { VStack, Heading, Box, Image,
    Divider, 
    Card, CardBody, 
    HStack,} from '@chakra-ui/react';


function FlightsCard(props) {
    const { title, description, image } = props;

    return (
        <>
            <Box
                w="340px"
                h='500px'
                borderRadius={'10px'}
                boxShadow={'md'}
                mx={'1em'}>
                <VStack>
                    <Box>
                        <Image
                            objectFit="containe"
                            borderRadius="md"
                            src={image}
                            alt="Image" />
                    </Box>
                    <VStack p="1em" align={'left'}>
                        <Heading
                            fontFamily={'Merriweather'}
                            color="black"
                            h="40px"
                            align={'left'}
                            fontSize={{ base: 'md', md: 'lg', lg: '24px' }}
                            fontWeight={600}>
                            {title}
                        </Heading>
                        <Divider />
                        <Heading
                            fontFamily={'Merriweather'}
                            py={'0.5em'}
                            noOfLines={9}
                            align={'left'}
                            h="140px"
                            color="black"
                            fontSize={{ base: 'sm', md: 'md', lg: '16px' }}
                            fontWeight={300}>
                            {description}
                        </Heading>
                    </VStack>
                </VStack>
            </Box>
        </>
    );
}
export function PackagesCard(props) {
        const { title, description, image, price } = props;
    
        return (
            <>
                <Card
                    w="375px"
                    h='670px'
                    borderRadius={'10px'}
                    boxShadow={'md'}
                    mx={'1em'}>
                    <VStack>
                        <Box>
                            <Image
                                objectFit="contain"
                                borderRadius="md"
                                src={image}
                                alt="Image" />
                        </Box>
                        <VStack p={{ base: '1em', md: '1em', lg: '1em' }} align={'left'}>
                            {/* <CardHeader> */}
                                <Heading
                                    fontFamily={'Merriweather'}
                                    color="black"
                                    h="30px" 
                                    align={'left'}
                                    fontSize={{ base: 'lg', md: 'lg', lg: '24px' }}
                                    fontWeight={600}>
                                    {title}
                                </Heading>
                            {/* </CardHeader> */}
                            
                            <Divider />

                            <CardBody >
                            <Heading
                                fontFamily={'Merriweather'}
                                noOfLines={9}
                                align="left"
                                h="190px"
                                color="black"
                                fontSize={{ base: 'sm', md: 'md', lg: '17px' }}
                                fontWeight={500}
                                whiteSpace="pre-wrap"
                                >
                                {description}
                            </Heading>

                                
                            </CardBody>
                            
                            <Divider />
                            <HStack px={{base: '0', md: '0.5em', lg: '0.5em'}} 
                            py="0.4em" align={'left'} w="100%">
                                <Heading
                                        fontFamily={'Merriweather'}
                                        color="#000000"
                                        align="center"
                                        fontSize={{ base: '24px', md: '24px', lg: '30px' }}
                                        fontWeight={400}>
                                        From
                                    </Heading>
                                <Heading
                                    fontFamily={'Merriweather'}
                                    color="#FF6B6B"
                                    fontSize={{ base: '24px', md: '24px', lg: '30px' }}
                                    fontWeight={600}>
                                    {price}
                                </Heading>
                            </HStack>
                          
                        </VStack>
                    </VStack>
                    
                </Card>
            </>
        );
    }

export default FlightsCard