import React from 'react'
import { VStack, Heading} from '@chakra-ui/react'
import ServicesHeader from '../components/templates/Services/ServicesHeader'
import image from '../assets/Pictures/Flight 1.jpg'
import FlightsLanding from '../components/templates/Services/FlightsLanding'
import FlightsInfo from '../components/templates/Services/FlightsInfo'
// import ChangePrices from '../components/elements/ChangePrices'

const Flights = () => {
  return (
    <>
      <VStack overflowX={'hidden'}>
        <ServicesHeader image={image} active="flights" />
        <FlightsLanding title="Flight Services"
        description="We understand that booking flights can be a time-consuming and daunting task. 
        That's why we offer a range of flight services to make your travel experience hassle-free.
        Our experienced travel experts will assist you in finding the best flights to your travel 
        destination, taking into consideration your budget, travel dates, and airline preferences. 
        We have partnerships with major airlines and travel providers worldwide, giving us access 
        to competitive fares and exclusive deals."
         />
          <Heading 
            fontFamily={'Merriweather'}
            color="black"
            h="60px"
            py="0.5em"
            pt={'1em'}
            align={'center'}
            fontSize={{base: 'xl',md: 'xl',lg: '32px'}} 
            fontWeight={600}>
            Popular Flights to Destination
          </Heading>  
         <FlightsInfo />
         {/* <ChangePrices color="#FFE071" 
         color2="#1E2756" 
         description="
         *Please Note that Prices may vary according to the current fair and conditions.  
         *You can learn more about the current prices and fares by Contacting Us." 
         link="/contact" /> */}
      </VStack>
    </>
  )
}

export default Flights