import React from 'react'
import {
    Stack,
    VStack,
    Box,
    Heading,
    SimpleGrid,
    Divider,
  } from "@chakra-ui/react";
import ServicesDescription from '../../modules/ServicesDescription';

const AttractionsLanding = (props) => {

    const { title, description } = props

    return (
    <>
        <Box  w={'100%'} >
            <Stack  bg="#FFC966" 
                    p={{base: '1em',md: '2em',lg: '3em'}} 
                    align="center" 
                    w={'100%'} 
                    direction={["column", "row"]} 
                    mt={{base: '-4em',md: '-6.2em',lg: '-6.2em'}} 
                    pt={{base: '4em',md: '6em',lg: '5em'}}  >
                <VStack w="100%" p="1em" align={'center'}>
                <Heading
                        fontSize={{ base: "lg", md: "xl", lg: "38" }}
                        fontWeight={700}
                        pt={'0.5em'}
                        fontFamily={'Merriweather'}
                    >
                        {title}
                    </Heading>
                    <Heading
                        fontSize={{ base: "md", md: "lg", lg: "24" }}
                        fontWeight={600}
                        fontFamily={'Merriweather'}
                        color={'#1E2756'}
                        pb={'1em'}
                    >
                        {description}
                    </Heading>
                    <Divider borderWidth="2px" borderColor="#1E2756" />
                    <SimpleGrid align={'center'} columns={[2, 3, 3]} spacing={[3, 5, 5]} >
                        <ServicesDescription title="Attraction Tickets" 
                                description="We will assist you in finding and purchasing tickets 
                                for the top attractions in your destination, ensuring you skip 
                                the lines and have a seamless experience." />
                        <ServicesDescription title="Tour Packages" 
                                description="We offer tour packages that allow you to explore the top 
                                attractions of different cities around the world, accompanied by knowledgeable tour guides." />
                        <ServicesDescription title="Adventure Activities" 
                                description="We offer Adventure activities in Dubai which are one of the best places to enjoy 
                                your holiday, Here is famous for its grand desert and the magnificent Arabian Gulf, Skydiving,
                                Ziplining, Bridge climbing, Exotic car driving and racing, Rafting, Sandboarding,
                                Bungy jumping, Rappelling.
                                " />
                        <ServicesDescription title="Theme Parks" 
                                description="UAE Theme Park Features Amazing Facilities & Exciting Activities for people of all age, 
                                IMG Worlds of Adventure, Ferrari World Theme Park, Legoland Water Park, Ski Dubai, Bollywood Park,
                                Wild Wadi Waterpark, Dubai Global Village These are places that have been designed to recreate a real 
                                or fantasy place." />
                        <ServicesDescription title="Culture and Heritage" 
                                description="The UAE is blessed with a rich heritage, Emiratis practice a variety of customs 
                                and traditions that are distinct and unique to their country, 
                                the architecture here reflects the age-old customs, traditions, lifestyle & the rich culture of UAE." />
                    </SimpleGrid>
                </VStack>
            </Stack>
        </Box>
    </>
    )
}

export default AttractionsLanding