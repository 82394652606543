import React from 'react'
import { VStack,} from '@chakra-ui/react'
import ServicesHeader from '../components/templates/Services/ServicesHeader'
import image from '../assets/Pictures/Services main.jpg'
import ServicesInfo from '../components/templates/Services/ServicesInfo'

// The image ratio should be 3:1
const Services = () => {
  return (
    <>
      <VStack overflowX={'hidden'}>
        <ServicesHeader image={image} active="null" />
        <ServicesInfo />
      </VStack>
    </>
  )
}

export default Services