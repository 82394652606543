import React from 'react'
import { 
    Box,
    VStack, 
    Link,
    Heading, 
    Image,
    Button, Divider
} from '@chakra-ui/react';


const HomeServicesCard = (props) => {
    const { image, title, description, link } = props;

    return (
        <VStack w={{ base: "260px", md: "350px", lg: "400px" }}
        h="auto"
        borderRadius={'10px'}
        boxShadow={'md'}
        mx={'1em'}>
            <Box>
                <Image
                    objectFit="contain"
                    borderRadius="md"
                    src={image}
                    alt="services"/>
            </Box>
            <VStack p={{ base: "0.4em", md: "0.4em", lg: "1em" }}
            align={'left'} >
                <Heading 
                    fontFamily={'Merriweather'}
                    fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }}
                    fontWeight={600}>
                    {title}
                </Heading>
                <Divider />
                <Heading
                    fontFamily={'Merriweather'}
                    py={{base: '0.2em',md: '0.4em',lg: '0.75em'}} 
                    noOfLines={14}
                    h={{base: '160px',md: '200px',lg: '200px'}} 
                    fontSize={{base: '13px',md: '13px',lg: '16px', xl: '18px'}} 
                    fontWeight={300}>
                    {description}
                </Heading>
            </VStack>
            {/* <Hide breakpoint='(max-width: 800px)' > */}
                    <Box pb={{base: '0.3em',md: '0.4em',lg: '0.75em'}}  
                    px="0.75em" align={'right'} w="100%" >
                        <Link href={link}>
                            <Button
                                w={{base: '100%',md: '75px',lg: '75px'}}
                                h="30px"
                                variant="solid"
                                bg="#1E2756"
                                borderRadius="10px"
                                color="white" 
                                
                            >
                                Details
                            </Button>
                        </Link>
                    </Box>
            {/* </Hide> */}
        </VStack>
    )
}

export default HomeServicesCard