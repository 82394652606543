import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Header from './components/templates/Header'
import Footer from './components/templates/Footer'
import Homepage from './pages/Homepage'
import Contact from './pages/Contact';
import Services from './pages/Services';
import About from './pages/About';
// import { useLocation } from 'react-router-dom';
import Packages from './pages/Packages';
import Flights from './pages/Flights';
import Hotels from './pages/Hotels';
import Visa from './pages/Visa'
import Attractions from './pages/Attractions';
import PrivacyAndPolicy from './pages/PrivacyAndPolicy';

function App() {

  // const location = useLocation();

  return (
    <>
    <Header/>
      <Router>
        <Routes >
          <Route exact path="/" element = {<Homepage/>} />
          <Route exact path="/about" element = {<About/>} />
          <Route exact path="/services" element = {<Services/>} />
          <Route exact path="/services/packages" element = {<Packages/>} />
          <Route exact path="/services/flights" element = {<Flights/>} />
          <Route exact path="/services/hotels" element = {<Hotels/>} />
          <Route exact path="/services/visa" element = {<Visa/>} />
          <Route exact path="/services/attractions" element = {<Attractions/>} />
          <Route exact path="/contact" element = {<Contact/>} />
          <Route exact path="/privacyandpolicy" element = {<PrivacyAndPolicy/>} />
        </Routes>
      </Router>
    <Footer/>
</>
  );
}

export default App;
