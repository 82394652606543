import React from 'react';
import { IconButton } from '@chakra-ui/react';

const ChakraIcon = React.forwardRef((props, ref) => {
  const handleNextClick = () => {
    ref.current.swiper.slideNext();
    ref.current.swiper.autoplay.start();
  };

  const handlePrevClick = () => {
    ref.current.swiper.slidePrev();
    ref.current.swiper.autoplay.start();
  };

  const name = props.name;
  const flag = props.flag;

  if (flag !== 1) {
    return (
      <IconButton
        ref={ref}
        aria-label="Next Slide"
        icon={name} 
        bg={'#1E2756'}
        color={'white'}
        onClick={handleNextClick}
        size={'lg'}
        zIndex={2}
        pos={'absolute'}
        top={{ base: "40%", md: "50%", lg: "50%" }}
        right={'20px'}
        borderRadius={'50%'}
        style={{
          transform: 'translateY(-50%)',
          boxShadow: '0px 0px 10px #1E2756',
        }}
      />
    );
  } else {
    return (
      <IconButton
        ref={ref}
        aria-label="Previous Slide"
        icon={name}
        onClick={handlePrevClick}
        bg={'#1E2756'}
        color={'white'}
        size={'lg'}
        zIndex={2}
        pos={'absolute'}
        top={{ base: "40%", md: "50%", lg: "50%" }}
        left={'20px'}
        borderRadius={'50%'}
        style={{
          transform: 'translateY(-50%)',
          boxShadow: '0px 0px 10px #1E2756',
        }}
      />
    );
  }
});

export default ChakraIcon;
