import { Button, 
    Flex, 
    Link, 
    HStack, 
    Show, 
    Spacer,
    Hide, 
    Box,
    VStack,
    Drawer, 
    DrawerBody, 
    DrawerHeader, 
    DrawerOverlay, 
    DrawerContent,
    DrawerCloseButton, 
    useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import Logo from '../elements/Logo'
import { GiHamburgerMenu } from "react-icons/gi";
// import '../../theme/index.css'

export default function Header() {

    // const [position, setPosition] = useState(0)
    // const [visible, setVisible] = useState(true) 
    // useEffect(()=> {
    //     const handleScroll = () => {
    //        let moving = window.pageYOffset
           
    //        setVisible(moving < position);
    //        setPosition(moving)
    //     };
    //     window.addEventListener("scroll", handleScroll);
    //     return(() => {
    //        window.removeEventListener("scroll", handleScroll);
    //     })
    // })

//   const cls = visible ? "visible" : "hidden";

 
    return (
        <Flex 
        w="100%"
        wrap="wrap"
        bg="#D45F46"
        h={'auto'}
        color='white'
        as="header" 
        justifyContent={'flex-start'}>
            <HStack w={{base: '80%', md: '40%', lg: '40%'}}>
                <Logo/>
            </HStack>
            <Spacer />
            <Show breakpoint='(max-width: 800px)'  >
                <Box 
                    px={'1em'}
                    w={{base: '20%', md: '20%', lg: '0'}}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <MobileHeader />
                </Box>
            </Show>

            <Hide breakpoint='(max-width: 800px)'>
                <HStack
                    w={{base: '0', md: '40%', lg: '60%'}}
                    justifyContent={'center'} 
                    px={'10em'}
                    gap={'2em'}
                    >
                    <Link href="/" 
                    >
                        <Button 
                        color="white"
                        borderRadius={50}
                        size='lg'
                        colorScheme='facebook'
                        bg = "#1E2756"
                        fontSize={'18px'}>
                            HOME
                        </Button>
                    </Link>
                    <Link href="/about">
                        <Button 
                        // color="white"
                        borderRadius={50}
                        size='lg'
                        colorScheme='facebook' 
                        variant='ghost'
                        fontSize={'18px'}>
                            ABOUT
                        </Button> 
                    </Link>
                    <Link href="/services"
                    >
                        <Button 
                        // color="white"
                        size='lg'
                        borderRadius={50}
                        colorScheme='facebook' 
                        variant='ghost'
                        fontSize={'18px'}>
                            SERVICES
                        </Button>
                    </Link>
                    <Link href="/contact" >
                        <Button 
                        // color="white"
                        size='lg'
                        borderRadius={50}
                        colorScheme='facebook' 
                        variant='ghost'
                        fontSize={'18px'}>
                            CONTACT
                        </Button> 
                    </Link>
                </HStack>
            </Hide>
        </Flex>
  )
}

export function MobileHeader(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
  
    return (
      <>
        <Link>
            <span ref={btnRef} colorScheme='teal' onClick={onOpen}>
                <GiHamburgerMenu/>
            </span>
        </Link>
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>SAS Travels</DrawerHeader>
            <DrawerBody>
            <VStack
                    w="100%"
                    justifyContent={'center'} >
                    <Link href="/">
                        <Button colorScheme='facebook' 
                                variant='ghost'>
                            Home
                        </Button>
                    </Link>
                    <Link href="/about">
                        <Button colorScheme='facebook' 
                                variant='ghost'>
                            About
                        </Button> 
                    </Link>
                    <Link href="/services">
                        <Button colorScheme='facebook' 
                                variant='ghost'>
                            Services
                        </Button>
                    </Link>
                    <Link href="/contact" >
                        <Button colorScheme='facebook' 
                                variant='ghost'>
                            Contact
                        </Button> 
                    </Link>
                </VStack>
            </DrawerBody>
            {/* <DrawerFooter>
            </DrawerFooter> */}
          </DrawerContent>
        </Drawer>
      </>
    )
  }