import React from "react";
import { VStack, Heading } from "@chakra-ui/react";
import ServicesHeader from "../components/templates/Services/ServicesHeader";
import image from "../assets/Pictures/Visa 1.jpg";
import VisaLanding from "../components/templates/Services/VisaLanding";
import VisaInfo from "../components/templates/Services/VisaInfo";

const Visa = () => {
  return (
    <>
      <VStack overflowX={'hidden'}>
        <ServicesHeader image={image} active="visa" />
        <VisaLanding
          title="Visa Services"
          description="We offer visa services to make your international travel experience hassle-free. 
          Our experienced team will guide you through the visa application process and ensure that you 
          have all the necessary documentation.
        We offer visa services for a range of countries, including popular travel destinations such as 
        the United States, the United Kingdom, Canada, Australia, and Schengen countries in Europe. "
        />
        <Heading
          fontFamily={'Merriweather'}
          color="black"
          h="60px"
          py="0.5em"
          pt={"1em"}
          align={"center"}
          fontSize={{ base: "xl", md: "xl", lg: "32px" }}
          fontWeight={600}
        >
          Visa Services for U.A.E
        </Heading>

        <Heading
          px={"1.5em"}
          py={"1em"}
          noOfLines={5}
          color="#1E2756"
          fontSize={{ base: "sm", md: "md", lg: "20px" }}
          fontFamily={'Merriweather'}
          fontWeight={500}
        >
         We always do our best to give our customers the lowest price on UAE tourist visas + 
         Medical Insurance (COVID) Our travel experts will provide complete information about 
         the visa documentation including an additional cost, flight ticket bookings, 
         hotel bookings and the trip itinerary, the visa application fee is non-refundable in all cases.
        </Heading>
        <VisaInfo />
      </VStack>
    </>
  );
};

export default Visa;
