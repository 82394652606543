import React from "react";
import {
  Stack,
  VStack,
  Box,
  Heading,
  Accordion,
} from "@chakra-ui/react";
import "../../../theme/index.css"
import AccordianCard from "../../modules/AccordianCard";
import { AdventureCard, LandmarksCard, ToursCard } from "../../modules/AttractionsCards";


const AttractionsInfo = () => {
  return (
    <>
      <Box  w={'100%'} overflowX={'hidden'}>
        <Stack  bg="#FFFFFF" 
                p={"3em"} 
                align="center" 
                w={'100%'} direction={["column", "row"]} 
                pt={'5em'} >
            <VStack w={{base: '100%', md: '50%', lg: '50%'}} p="1em" align={'left'}>
              <Heading
                fontSize={{ base: "lg", md: "2xl", lg: "42" }}
                fontWeight={600}
                fontFamily={"Rubik"}
              >
                Adventure and Entertainment
              </Heading>
              <Accordion>
                <AccordianCard 
                    title="Dubai Frame" 
                    description="Take in stunning views of the city from this iconic structure that frames Dubai's skyline."
                    color="#1E2756"
                />
                <AccordianCard 
                    title="Jet Ski Ride" 
                    description="Feel the rush of the wind in your hair and the waves beneath your feet with an exhilarating jet ski ride."
                    color="#1E2756"
                />
                <AccordianCard 
                    title="Ferrari World " 
                    description="Experience the thrill of speed and excitement with this indoor amusement park that features Formula One-themed rides and attractions."
                    color="#1E2756"
                />
                 <AccordianCard 
                    title="Laguana Waterpark" 
                    description="Escape the heat with this family-friendly waterpark that features a range of water rides and attractions."
                    color="#1E2756"
                />
                <AccordianCard 
                    title="Atlantis Waterpark" 
                    description=" Enjoy an aquatic adventure at this resort-style waterpark that features thrilling water rides and attractions."
                    color="#1E2756"
                />
                <AccordianCard 
                    title="MotionGate" 
                    description="Step into the world of Hollywood with this theme park that features attractions based on popular movies and TV shows."
                    color="#1E2756"
                />
                <AccordianCard 
                    title="IMG World of Adventure" 
                    description="Enter a world of adventure and fantasy with this indoor amusement park that features attractions based on popular cartoons and comics."
                    color="#1E2756"
                />
                <AccordianCard 
                    title="Yas Water World Abu Dhabi" 
                    description="Enjoy a day of water fun at this waterpark that features a range of slides, rides, and attractions."
                    color="#1E2756"
                />
                </Accordion>
            </VStack>
            <VStack w={{base: '100%', md: '50%', lg: '50%'}}
                    >
                <AdventureCard />
            </VStack>
        </Stack>
        <Stack  bg="#1E2756" 
                p={"3em"} align="center" 
                w={'100%'} direction={["column", "row"]} 
                pt={'5em'} >
            <VStack w={{base: '100%', md: '50%', lg: '50%'}}>
              <ToursCard />
            </VStack>
            <VStack w={{base: '100%', md: '50%', lg: '50%'}}
             p="1em" align={'left'}>
              <Heading
                fontSize={{ base: "lg", md: "2xl", lg: "42" }}
                fontWeight={600}
                fontFamily={"Rubik"}
                color={'#FFC966'}
              >
                Tours and Sightseeing
              </Heading>
              <Accordion>
              <AccordianCard 
                    title="Dubai City Tour" 
                    description="Discover the best of Dubai's landmarks and attractions with our city tour that showcases the city's unique blend of modern and traditional architecture."
                    color="#FFC966"
                />
                <AccordianCard 
                    title="Sharjah City Tours" 
                    description="Explore the cultural capital of the UAE with our tour of Sharjah that takes you to the city's top cultural landmarks and museums."
                    color="#FFC966"
                />
                <AccordianCard 
                    title="Abu Dhabi City Tours" 
                    description="Visit the capital of the UAE and see its iconic landmarks, including the Sheikh Zayed Grand Mosque and the Emirates Palace."
                    color="#FFC966"
                />
                <AccordianCard 
                    title="Scuba Diving Tour" 
                    description="Dive into the crystal clear waters of the UAE and explore the underwater world with our scuba diving tour."
                    color="#FFC966"
                />
                <AccordianCard 
                    title="Desert Safari" 
                    description="Experience the beauty and adventure of the UAE's deserts with our desert safari that includes dune bashing, camel riding, and a traditional Bedouin camp experience."
                    color="#FFC966"
                />
                <AccordianCard 
                    title="Deluxe Desert Safari" 
                    description="Upgrade your desert safari experience with our deluxe package that includes a private vehicle, a BBQ dinner, and entertainment under the stars."
                    color="#FFC966"
                />
                <AccordianCard 
                    title="Dubai Helicopter Tour" 
                    description="Get a bird's eye view of Dubai's iconic landmarks with our helicopter tour that takes you over the Burj Khalifa, Palm Jumeirah, and more."
                    color="#FFC966"
                />
                <AccordianCard 
                    title="Ras-Al-Khaimah Tour" 
                    description="Discover the natural beauty of the UAE's northern emirate with our tour of Ras Al-Khaimah that includes a visit to the stunning Jais Mountain."
                    color="#FFC966"
                />
              </Accordion>
                           
            </VStack>
            
        </Stack>
        <Stack  bg="#FFFFFF" 
                p={"3em"} align="center" 
                w={'100%'} direction={["column", "row"]} pt={'5em'} >
            <VStack w={{base: '100%', md: '50%', lg: '50%'}}
             p="1em" align={'left'}>
              <Heading
                fontSize={{ base: "lg", md: "2xl", lg: "42" }}
                fontWeight={600}
                fontFamily={"Rubik"}
              >
                Landmarks and Cultural Sites
              </Heading>
              <Accordion>
              <AccordianCard 
                    title="Burj Khalifa" 
                    description="Visit the world's tallest building and take in breathtaking views from the observation deck."
                    color="#1E2756"
                />
              <AccordianCard 
                    title="Museum of the Future" 
                    description="Experience the cutting edge of technology and innovation at this futuristic museum that showcases the latest advances in science and design."
                    color="#1E2756"
                />
                <AccordianCard 
                    title="Dubai Dolphinarium" 
                    description="Meet and interact with dolphins, seals, and other marine animals at this family-friendly attraction."
                    color="#1E2756"
                />
                <AccordianCard 
                    title="Dubai Dhow Cruise Dinner" 
                    description="Enjoy a romantic dinner cruise on a traditional dhow boat and take in the stunning views of Dubai's skyline."
                    color="#1E2756"
                />
              <AccordianCard 
                    title="Bollywood Park and Resort" 
                    description="Experience the glitz and glamour of Bollywood with this theme park that features rides and attractions based on India's film industry."
                    color="#1E2756"
                />
              </Accordion>
                           
            </VStack>
            <VStack w={{base: '100%', md: '50%', lg: '50%'}}>
                <LandmarksCard />
            </VStack>
        </Stack>   
      </Box>
      </>
  )
}

export default AttractionsInfo