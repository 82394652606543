import React from 'react'
import { VStack } from '@chakra-ui/react'
import ServicesHeader from '../components/templates/Services/ServicesHeader'
import image from '../assets/Pictures/Attractions 1.jpg'
import AttractionsLanding from '../components/templates/Services/AttractionsLanding'
import AttractionsInfo from '../components/templates/Services/AttractionsInfo'

const Attractions = () => {
  return (
    <>
      <VStack overflowX={'hidden'}>
        <ServicesHeader image={image} active="attractions" />
        <AttractionsLanding title="U.A.E Attractions"
        description="We understand that exploring the top attractions of a destination is an 
        essential part of any travel experience. That's why we offer a comprehensive range of 
        attraction services to help you make the most of your trip.
        Our travel experts have partnered with leading attraction providers worldwide to 
        offer a wide selection of attractions, ranging from popular tourist spots to off-the-beaten-path 
        destinations. We can help you find the ideal attractions for your travel needs,
        whether you're traveling for leisure, business, or education."
         />
        <AttractionsInfo />
      </VStack>
    </>
  )
}

export default Attractions