import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { HStack, } from "@chakra-ui/react";
import SwiperCore, { EffectCreative, Pagination, Navigation, Autoplay } from "swiper/core";
import ChakraIcon from "../../elements/ChakraIcon";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-flip";
import "swiper/css/navigation";
import { PackagesCard } from "../../modules/PackagesAndFligthsCard";
import packages1 from "../../../assets/Pictures/Services/Services-1.jpg";
import packages2 from "../../../assets/Pictures/Packages/Packages2.jpg";
import packages3 from "../../../assets/Pictures/Packages/Packages3.jpg";
import packages4 from "../../../assets/Pictures/Packages/Packages4.jpg";

SwiperCore.use([Pagination]);
SwiperCore.use([Navigation]);
SwiperCore.use([Autoplay]);

const PackagesInfo = () => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const slides = [
    // The image ratio should be 4:3
    {
      heading: "Umrah Package",
      image: packages1,
      price: "AED 2100/ 2500",
      description: `Inclusions:
Luxury Bus/ Flight + Hotel + Transfer
Stay: 05 Nights Mecca/ 03 Nights Medina
Hotel: 3* Hotels
Room Type: Standard Double/ Triple/ Quad
Visa: Umrah Visa/ Multiple Tourist Visa`
    },
    {
      heading: "Georgia Package",
      image: packages2,
      price: "AED 2299",
      description: `Inclusions:
Flight + Hotel + Transfer
Stay: 05 Nights/ 06 Days
Destination: Tbilisi, Georgia
Flight Details: From Sharjah / Dubai to Tbilisi
Hotel: Ibis Budget Tbilisi 3*
Room Type: Standard Double / Twin Room with Bed & Breakfast
`
    },
    {
      heading: "Explore Europe Package",
      image: packages3,
      price: "AED 4321",
      description: `Inclusions: 
Flight + Hotel + Transfer
Duration: 06 Nights/ 07 Days
Stay: France, Belgium, Netherland, Germany and more
Flight Details: To Paris, France
Hotel: 3* Hotels throughout the tour
Room Type: Standard Double/Twin Room with Bed & Breakfast
`
    },
    {
      heading: "Bratislava Package",
      image: packages4,
      price: "AED 2250",
      description: `Package: Hotel + Transfer
Duration: 05 Nights/ 06 Days
Stay: Bratislava, Slovakia
Flight Details: To Bratislava (Ticket as added on)
Hotel: 4* Radisson Blu Carlton 
Room Type: Standard Double/Twin Room with Bed & Breakfast
`
    },
  ];

  return (
    <>
      <HStack align={'center'} w="100%" >
        <Swiper
          slidesPerView={(window.innerWidth < 800) ? 1 : 2}
          // effect={"creative"}
          modules={[EffectCreative, Pagination, Navigation]}
        //   grabCursor={true}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          pagination={{
            clickable: true,
            el: ".swiper-pagination",
            type: "bullets",
            bulletClass: "my-bullet",
            bulletActiveClass: "my-bullet-active",
          }}
          autoplay={{
            delay: 10000, // autoplay each image for 5 seconds
            disableOnInteraction: false, // enable autoplay to continue after user interaction
          }}
          className="mySwiper"
          style={{
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          {slides.map((slide, index) => (
            <React.Fragment key={index}>
              <SwiperSlide>
              <HStack
                py={'1em'}
                color="white"
                zIndex="2"
                px={{base: '2em',md: '1em',lg: '0.5em'}}
              >
                <PackagesCard title={slide.heading} 
                image={slide.image} 
                price={slide.price}
                description={slide.description}/>
              </HStack>
                
              </SwiperSlide>
            </React.Fragment>
          ))}

          <ChakraIcon
            ref={navigationPrevRef}
            name={<ChevronLeftIcon />}
            flag={1}
          />
          <ChakraIcon ref={navigationNextRef} 
          name={<ChevronRightIcon />} />
          <div className="swiper-pagination"></div>

        </Swiper>
      </HStack>
    </>
  );
};

export default PackagesInfo;
