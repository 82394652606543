import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper/core";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import { 
    Box,
    Image, 
} from '@chakra-ui/react';

// import required modules
import { EffectCards } from "swiper";

import adventure1 from "../../assets/Pictures/Attractions/Adventure1.jpg"
import adventure2 from "../../assets/Pictures/Attractions/Adventure2.jpg"
import adventure3 from "../../assets/Pictures/Attractions/Adventure3.jpg"
import adventure4 from "../../assets/Pictures/Attractions/Adventure4.jpg"
import adventure5 from "../../assets/Pictures/Attractions/Adventure5.jpg"
import adventure6 from "../../assets/Pictures/Attractions/Adventure6.jpg"
import tour1 from "../../assets/Pictures/Attractions/Tours1.jpg"
import tour2 from "../../assets/Pictures/Attractions/Tours2.jpg"
import tour3 from "../../assets/Pictures/Attractions/Tours3.jpg"
import tour4 from "../../assets/Pictures/Attractions/Tours4.jpg"
import tour5 from "../../assets/Pictures/Attractions/Tours5.jpg"
import tour6 from "../../assets/Pictures/Attractions/Tours6.jpg"
import tour7 from "../../assets/Pictures/Attractions/Tours7.jpg"
import tour8 from "../../assets/Pictures/Attractions/Tours8.jpg"
import landmark1 from "../../assets/Pictures/Attractions/Landmark1.jpg"
import landmark2 from "../../assets/Pictures/Attractions/Landmark2.jpg"
import landmark3 from "../../assets/Pictures/Attractions/Landmark3.jpg"
import landmark4 from "../../assets/Pictures/Attractions/Landmark4.jpg"
// import landmark5 from "../../assets/Pictures/Attractions/Landmark5.jpg"
import '../../theme/swipercards.css'

SwiperCore.use([Autoplay]);

export function AdventureCard() {
    // The Images Ratio should be 3:2
  return (
    <>
      <Swiper
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
        style={{ width: "300px", height: "450px" }}
        autoplay={{
        delay: 4000, // autoplay each image for 5 seconds
        disableOnInteraction: false, // enable autoplay to continue after user interaction
        }}
      >
        <SwiperSlide>
        <Box>
            <Image
                objectFit="contain"
                borderRadius="md"
                src={adventure1}
                alt="picture"/>
    
        </Box>
        </SwiperSlide>
        <SwiperSlide>
        <Box>
            <Image
                objectFit="contain"
                borderRadius="md"
                src={adventure2}
                alt="picture"/>
    
        </Box>
        </SwiperSlide>
        <SwiperSlide>
        <Box>
            <Image
                objectFit="contain"
                borderRadius="md"
                src={adventure3}
                alt="picture"/>
    
        </Box>
        </SwiperSlide>
        <SwiperSlide>
        <Box>
            <Image
                objectFit="contain"
                borderRadius="md"
                src={adventure4}
                alt="picture"/>
    
        </Box>
        </SwiperSlide>
        <SwiperSlide>
        <Box>
            <Image
                objectFit="contain"
                borderRadius="md"
                src={adventure5}
                alt="picture"/>
    
        </Box>
        </SwiperSlide>
        <SwiperSlide>
        <Box>
            <Image
                objectFit="contain"
                borderRadius="md"
                src={adventure6}
                alt="picture"/>
    
        </Box>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export function ToursCard() {
    // The Images Ratio should be 3:2
  return (
    <>
      <Swiper
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
        style={{ width: "300px", height: "450px" }}
        autoplay={{
        delay: 4000, // autoplay each image for 5 seconds
        disableOnInteraction: false, // enable autoplay to continue after user interaction
        }}
      >
        <SwiperSlide>
        <Box>
            <Image
                objectFit="contain"
                borderRadius="md"
                src={tour1}
                alt="picture"/>
        </Box>
        </SwiperSlide>
        <SwiperSlide>
        <Image
                objectFit="contain"
                borderRadius="md"
                src={tour2}
                alt="picture"/>
        </SwiperSlide>
        <SwiperSlide>
          <Box>
            <Image
                objectFit="contain"
                borderRadius="md"
                src={tour3}
                alt="picture"/>
    
        </Box></SwiperSlide>
        <SwiperSlide>
        <Box>
            <Image
                objectFit="contain"
                borderRadius="md"
                src={tour4}
                alt="picture"/>
    
        </Box>
        </SwiperSlide>
        <SwiperSlide>
        <Box>
            <Image
                objectFit="contain"
                borderRadius="md"
                src={tour5}
                alt="picture"/>
    
        </Box>
        </SwiperSlide>
        <SwiperSlide>
        <Box>
            <Image
                objectFit="contain"
                borderRadius="md"
                src={tour6}
                alt="picture"/>
    
        </Box>
        </SwiperSlide>
        <SwiperSlide>
        <Image
                objectFit="contain"
                borderRadius="md"
                src={tour7}
                alt="picture"/>
        </SwiperSlide>
        <SwiperSlide>
        <Image
                objectFit="contain"
                borderRadius="md"
                src={tour8}
                alt="picture"/>
        </SwiperSlide>
      </Swiper>
    </>
  );
}


export function LandmarksCard() {
    // The Images Ratio should be 3:2
  return (
    <>
      <Swiper
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
        style={{ width: "300px", height: "450px" }}
        autoplay={{
        delay: 4000, // autoplay each image for 5 seconds
        disableOnInteraction: false, // enable autoplay to continue after user interaction
        }}
      >
        <SwiperSlide>
        <Box>
            <Image
                objectFit="contain"
                borderRadius="md"
                src={landmark1}
                alt="picture"/>
        </Box>
        </SwiperSlide>
        <SwiperSlide>
        <Box>
            <Image
                objectFit="contain"
                borderRadius="md"
                src={landmark2}
                alt="picture"/>
        </Box>
        </SwiperSlide>
        <SwiperSlide>
        <Box>
            <Image
                objectFit="contain"
                borderRadius="md"
                src={landmark3}
                alt="picture"/>
        </Box>
        </SwiperSlide>
        <SwiperSlide>
        <Box>
            <Image
                objectFit="contain"
                borderRadius="md"
                src={landmark4}
                alt="picture"/>
        </Box>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
