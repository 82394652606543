import React from 'react'
import { Box, Image, SimpleGrid } from '@chakra-ui/react'

const ServicesImage = (props) => {

    const { image1, image2, image3, image4 } = props;

  return (
    <Box p={{ base: "0", md: "1em", lg: "2em" }} >
        <SimpleGrid columns={2} spacing={3}>
            <Box>
                <Image
                    objectFit="containe"
                    borderRadius="md"
                    src={image1}
                    alt="services"/>
            </Box>
            <Box>
                <Image
                    objectFit="containe"
                    borderRadius="md"
                    src={image2}
                    alt="services"/>
            </Box>
            <Box>
                <Image
                    objectFit="containe"
                    borderRadius="md"
                    src={image3}
                    alt="services"/>
            </Box>
            <Box>
                <Image
                    objectFit="containe"
                    borderRadius="md"
                    src={image4}
                    alt="services"/>
            </Box>    
        </SimpleGrid>
        

    </Box>
  )
}

export default ServicesImage