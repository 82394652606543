import React from 'react'
import { VStack,} from '@chakra-ui/react'
import ServicesHeader from '../components/templates/Services/ServicesHeader'
import image from '../assets/Pictures/Hotel 1.jpg'
import HotelLanding from '../components/templates/Services/HotelLanding'
import HotelInfo from '../components/templates/Services/HotelInfo'


const Hotels = () => {
  return (
    <>
      <VStack overflowX={'hidden'}>
        <ServicesHeader image={image} active="hotels" />
        <HotelLanding title="Hotel Services"
        description="We understand that finding the perfect accommodation is essential to a 
        comfortable and enjoyable travel experience. That's why we offer a comprehensive 
        range of hotel services to cater to different preferences and budgets.
        Our hotel experts have partnered with leading hotel providers worldwide to 
        offer a wide selection of hotels, ranging from budget-friendly options to 
        luxurious resorts. We can help you find the ideal hotel for your travel needs, 
        whether you're traveling for leisure, business, or education."
         />
         <HotelInfo/>
      </VStack>
    </>
  )
}

export default Hotels