import React from 'react'
import {
    Stack,
    VStack,
    Box,
    Heading,
    SimpleGrid,
    Divider,
  } from "@chakra-ui/react";
import ServicesDescription from '../../modules/ServicesDescription';

const FlightsLanding = (props) => {

    const { title, description } = props

    return (
    <>
        <Box  w={'100%'} >
            <Stack  bg="#FFC966" 
                    p={{base: '1em',md: '2em',lg: '3em'}} 
                    align="center" 
                    w={'100%'} 
                    direction={["column", "row"]} 
                    mt={{base: '-4em',md: '-6.2em',lg: '-6.2em'}} 
                    pt={{base: '4em',md: '6em',lg: '5em'}}  >
                <VStack w="100%" p="1em" align={'center'}>
                <Heading
                        fontSize={{ base: "lg", md: "xl", lg: "38" }}
                        fontWeight={700}
                        pt={'0.5em'}
                        fontFamily={'Merriweather'}
                    >
                        {title}
                    </Heading>
                    <Heading
                        fontSize={{ base: "md", md: "lg", lg: "24" }}
                        fontWeight={600}
                        fontFamily={'Merriweather'}
                        color={'#1E2756'}
                        pb={'1em'}
                    >
                        {description}
                    </Heading>
                    <Divider borderWidth="2px" borderColor="#1E2756" />
                    <SimpleGrid align={'center'} columns={[2, 3, 3]} spacing={[3, 5, 5]} >
                        <ServicesDescription title="Flight Reservations" 
                                description="We will help you find and reserve flights that best fit your travel needs and budget." />
                        <ServicesDescription title="Group Travel Booking" 
                                description="We offer group travel services for families, friends, and corporate groups. We can help you find and book flights for groups of any size." />
                        <ServicesDescription title="Corporate Travel Management" 
                                description="We provide the best travel deals for corporate travel meetings, conferences and events seamless service that attends to your people's most basic travel and accommodation" />
                        <ServicesDescription title="Travel Insurance" 
                                description="We offer travel insurance that covers flight cancellations, delays, and other unforeseen circumstances that may disrupt your travel plans." />
                    </SimpleGrid>
                </VStack>
            </Stack>
        </Box>
    </>
    )
}

export default FlightsLanding