import React from "react";
import Hero from "../components/templates/Homepage/Hero";
import HomeServices from "../components/templates/Homepage/HomeServices";
import HomeOffers from "../components/templates/Homepage/HomeOffers";
import HomeHighlights from "../components/templates/Homepage/HomeHighlights";
import InfoDivider from "../components/templates/Homepage/InfoDivider";

const Homepage = () => {
  return (
    <>
      <Hero />
      <HomeServices />
      <HomeOffers />
      <HomeHighlights />
      <InfoDivider />
    </>
  );
};

export default Homepage;
