import React from 'react'
import { VStack, Box, Heading , Divider, HStack, Stack} from '@chakra-ui/react'
import HotelFormCard from '../../modules/HotelFormCard'
import { Player } from '@lottiefiles/react-lottie-player';
import LottieMap from '../../../assets/Lottie/Hotel.json';

const HotelInfo = () => {
  return (
    <>
      <VStack p={'1.5em'} w="100%">
        <Box 
        bg="white" w="100%"       
        align={'left'} >
            <Heading  
                      px={'1.5em'}
                      py={'0.5em'}
                      color={'#000000'}
                      fontSize={{base: 'sm',md: 'md',lg: '32px'}} 
                      fontFamily={'Merriweather'}
                      fontWeight={600}>What We Offer?</Heading>
            <Heading  px={'1.5em'}
                      py={'1em'}
                      noOfLines={7}
                      color="#1E2756" 
                      fontSize={{base: 'sm',md: 'md',lg: '20px'}} fontFamily={'Merriweather'}
                      fontWeight={500}>
                        Why waste time for searching several hotels to find a cheaper one, 
                        when our experts have already done it. We provide always the best deal possible. 
                        With this at heart, Garnet Hotel team has partnered with local hotel chains/properties 
                        to provide you with a best deal at affordable rates.
                        <br></br>
                        Be it hotels in U.A.E - Accordingly budget or trip purpose -
                        We have aced the way to find the best hotels options in UAE & other Nation to 
                        ensure your travel affordable and comfortable. 
                        <br></br>
                        Our priority is to ensure customer satisfaction by providing 
                        personalized attraction services that meet your specific needs. 
                        Contact us today to learn more about our attraction services and 
                        let us help you plan your next trip.
                        </Heading>
                <Divider borderWidth="2px" borderColor="#1E2756" />
                <Heading  pt={'0.75em'}
                      pb={'0.75em'}
                      px={'1.5em'}
                      color={'#000000'}
                      fontSize={{base: 'sm',md: 'md',lg: '32px'}} 
                      fontFamily={'Merriweather'}
                      fontWeight={600}>
                        Looking for a comfortable and convenient stay in Dubai, UAE or any other place? 
                      </Heading>
              <Heading
                      py={'1em'}
                      noOfLines={5}
                      px={'1.5em'}
                      color="#1E2756" 
                      fontSize={{base: 'sm',md: 'md',lg: '20px'}} fontFamily={'Merriweather'}
                      fontWeight={500}>
                       Fill out the form below and send us to book your stay at one of our hotels.
                        </Heading>
                <Divider borderWidth="2px" borderColor="#1E2756" w={'50%'} />

                <HStack w={'100%'} my={'1em'} py={'0.30em'}
                boxShadow={'35px 35px 65px rgba(146, 106, 120, 0.16)'} 
                align={'center'}
                >
                  <HotelFormCard />
                </HStack>

                <Stack bg={"#FFC966"} direction={[ 'column', 'row']}>
                  <VStack w={{base: '100%', md: '70%', lg: '80%'}} p={'2em'}>
                  <Heading
                      py={'1em'}
                      color="#1E2756" 
                      fontSize={{base: 'md',md: 'lg',lg: '2xl'}} fontFamily={'Merriweather'}
                      fontWeight={500}>
                      Once you submit the form, our hotel experts will get back to you with 
                      the available options that fit your travel dates and preferences. 
                      We offer a wide selection of hotels in Dubai, 
                      ranging from budget-friendly options to luxurious resorts. <br></br><br></br>

                      Our hotel services include airport transfers, complimentary breakfast, 
                      and a range of amenities to ensure a comfortable and enjoyable stay. 
                      Contact us today to book your stay and let us help you plan your next trip.
                        </Heading>
                  </VStack>
                  <HStack w={{base: '100%', md: '30%', lg: '20%'}} p={'2em'}>
                  <Player autoplay
                                  controls
                                  loop
                                  mode="normal"
                                  src={LottieMap}>
                          </Player>
                          </HStack>
                </Stack>
        </Box>
      </VStack>
    </>
  )
}

export default HotelInfo