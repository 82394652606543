import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { HStack } from "@chakra-ui/react";
import SwiperCore, {
  EffectCreative,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/core";
import ChakraIcon from "../../elements/ChakraIcon";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-flip";
import "swiper/css/navigation";
import PackagesAndFligthsCard from "../../modules/PackagesAndFligthsCard";
import visa1 from "../../../assets/Pictures/Flights/Flights1.jpg";
import visa2 from "../../../assets/Pictures/Visa/Visa2.jpg";
import visa3 from "../../../assets/Pictures/Visa/Visa3.jpg";

SwiperCore.use([Pagination]);
SwiperCore.use([Navigation]);
SwiperCore.use([Autoplay]);

const VisaInfo = () => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const slides = [
    // The image ratio should be 3:2
    {
      heading: "30 Days Visa",
      image: visa1,
      price: "",
      description:
        "Need to stay in the UAE for a month? Our visa services offer you a hassle-free experience, with quick and easy processing of your 1 month UAE visa. ",
    },
    {
      heading: "Transit Visa",
      image: visa2,
      price: "",
      description:
        "Stopover in the UAE and want to explore the city for a few days? Our transit visa services offer you the convenience of a short-term visa, allowing you to make the most of your layover.",
    },
    {
      heading: "60 Days Visa",
      image: visa3,
      price: "",
      description:
        "Whether you're visiting friends, family or just need to stay a bit longer in the UAE, our 2 month UAE visa service has got you covered. With our expert visa services, you can enjoy a seamless application process and get your visa approved in no time.",
    }
  ];

  return (
    <>
      <HStack position="relative" w="100%">
        <Swiper
          slidesPerView={(window.innerWidth < 800) ? 1 : 3}
          //   effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          modules={[EffectCreative, Pagination, Navigation]}
          //   grabCursor={true}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          pagination={{
            clickable: true,
            el: ".swiper-pagination",
            type: "bullets",
            bulletClass: "my-bullet",
            bulletActiveClass: "my-bullet-active",
          }}
          autoplay={{
            delay: 5000, // autoplay each image for 5 seconds
            disableOnInteraction: false, // enable autoplay to continue after user interaction
          }}
          className="mySwiper"
          style={{
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {slides.map((slide, index) => (
            <React.Fragment key={index}>
              <SwiperSlide>
                <HStack py={"1em"} color="white" zIndex="2" px={"0.5em"}>
                  <PackagesAndFligthsCard
                    title={slide.heading}
                    image={slide.image}
                    price={slide.price}
                    description={slide.description}
                  />
                </HStack>
              </SwiperSlide>
            </React.Fragment>
          ))}

          <ChakraIcon
            ref={navigationPrevRef}
            name={<ChevronLeftIcon />}
            flag={1}
          />
          <ChakraIcon ref={navigationNextRef} name={<ChevronRightIcon />} />

          <HStack className="swiper-pagination"></HStack>
        </Swiper>
      </HStack>
    </>
  );
};

export default VisaInfo;
