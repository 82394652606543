import React from 'react'
import { VStack, HStack, Heading } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons'

const InfoCard = (props) => {
    const { title, icon, description } = props;

    return (
    <VStack
        mx="1em"
        p="0.5em"
        background="transparent"
        gap="10px"
        borderRadius={'16px'}
        alignItems="left"
        pl={3}
        w={{base: '200px', md: '250px', lg:'270px', xl: 'auto'}}
        h={{base: '250px', md: '250px', lg:'250px', xl: '280px'}}
        overflow="hidden">
        <HStack pt="0.5em" 
                fontSize={{ base: "lg", md: "xl", lg: "28px", xl : "36px" }}>
              {icon}
        </HStack>
        <HStack>
            <Heading fontSize={{ base: "md", md: "lg", lg: "22px", xl : "24px" }}
            fontFamily={'Merriweather'}
            fontWeight={550} pt="10px">
                {title}
            </Heading>
        </HStack>
        <HStack>
            <Heading 
            fontSize={{base: 'sm',md: 'md',lg: '18px', xl: '20px'}} 
            fontFamily={'Merriweather'}
            noOfLines={{base: 7, md: 6, lg: 4}}
            fontWeight={400} pr={5}>
                {description}
            </Heading>
        </HStack>
    </VStack>
    )
}

InfoCard.defaultProps = {
    icon: <AddIcon/>,
    title: "",
    description: ""
}
export default InfoCard