import React from 'react'
import {
    Stack,
    VStack,
    Box,
    Heading,
    SimpleGrid,
    Divider,
  } from "@chakra-ui/react";
import ServicesDescription from '../../modules/ServicesDescription';

const HotelLanding = (props) => {

    const { title, description } = props

    return (
    <>
        <Box  w={'100%'} >
            <Stack  bg="#FFC966" 
                    p={{base: '1em',md: '2em',lg: '3em'}} 
                    align="center" 
                    w={'100%'} 
                    direction={["column", "row"]} 
                    mt={{base: '-4em',md: '-6.2em',lg: '-6.2em'}} 
                    pt={{base: '4em',md: '6em',lg: '5em'}}  >
                <VStack w="100%" p="1em" align={'center'}>
                    <Heading
                        fontSize={{ base: "lg", md: "xl", lg: "38" }}
                        fontWeight={700}
                        pt={'0.5em'}
                        fontFamily={'Merriweather'}
                    >
                        {title}
                    </Heading>
                    <Heading
                        fontSize={{ base: "md", md: "lg", lg: "24" }}
                        fontWeight={600}
                        fontFamily={'Merriweather'}
                        color={'#1E2756'}
                        pb={'1em'}
                    >
                        {description}
                    </Heading>
                    <Divider borderWidth="2px" borderColor="#1E2756" />
                    <SimpleGrid align={'center'} columns={[2, 3, 3]} spacing={[3, 5, 5]} >
                        <ServicesDescription title="Hotel Reservations & Amenities" 
                                description="We will assist you in finding and reserving the best hotels for your stay, 
                                taking into consideration your budget and preferences. 
                                We can assist you in finding hotels that offer specific amenities, 
                                such as spas, fitness centers, swimming pools, and more." />
                        <ServicesDescription title="Hotel Deals" 
                                description="We have partnerships with leading hotel providers that offer exclusive 
                                deals and discounts on their properties. 
                                We will help you find the best deals that fit your travel plans and budget." />
                        <ServicesDescription title="Group Accommodation" 
                                description="We offer group accommodation services for families, 
                                friends, and corporate groups. We can help you find and book hotels for groups of any size." />
                    </SimpleGrid>
                </VStack>
            </Stack>
        </Box>
    </>
    )
}

export default HotelLanding