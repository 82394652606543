import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { HStack } from "@chakra-ui/react";
import SwiperCore, {
  EffectCreative,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/core";
import ChakraIcon from "../../elements/ChakraIcon";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-flip";
import "swiper/css/navigation";
import FlightsCard from "../../modules/PackagesAndFligthsCard";
import flights1 from "../../../assets/Pictures/Flights/Flights1.jpg";
import flights2 from "../../../assets/Pictures/Flights/Flights2.jpg";
import flights3 from "../../../assets/Pictures/Flights/Flights3.jpg";
import flights4 from "../../../assets/Pictures/Flights/Flights4.jpg";
import flights5 from "../../../assets/Pictures/Flights/Flights5.jpg";
import flights6 from "../../../assets/Pictures/Flights/Flights6.jpg";
import flights7 from "../../../assets/Pictures/Flights/Flights7.jpg";

SwiperCore.use([Pagination]);
SwiperCore.use([Navigation]);
SwiperCore.use([Autoplay]);

const FlightsInfo = () => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const slides = [
    // The image ratio should be 2:1
    {
      heading: "Flights To Dubai",
      image: flights1,
      description:
        "Experience the extravagance and modernity of the city of Dubai, with its luxurious shopping, impressive architecture, and thrilling nightlife. Book your flight to Dubai and explore the glittering metropolis, with non-stop flights from major cities around the world.",
    },
    {
      heading: "Flights To Istanbul",
      image: flights2,
      description:
        "Immerse yourself in the captivating city of Istanbul, where East meets West and ancient history meets modern culture. Find the best deals on flights to Istanbul, with multiple airlines offering non-stop flights from major cities across the globe",
    },
    {
      heading: "Flights To London",
      image: flights3,
      description:
        "Explore the iconic city of London, with its world-class museums, royal landmarks, and buzzing atmosphere. Book your flight to London and discover the best of this vibrant capital, with a range of airlines offering direct and connecting flights from cities worldwide.",
    },
    {
      heading: "Flights To Manila",
      image: flights4,
      description:
        "Discover the vibrant capital of the Philippines, Manila, with its rich cultural heritage, bustling street life, and delicious food. Find great deals on flights to Manila, with many airlines offering direct and connecting flights from major cities around the world.",
    },
    {
      heading: "Flights To Delhi",
      image: flights5,
      description:
        "Discover the vibrant capital of India, New Delhi, with its bustling markets, rich history, and delicious cuisine. Plan your trip and book your flight to New Delhi, with a range of airlines offering daily direct and connecting flights from many cities worldwide.",
    },
    {
      heading: "Flights To Mumbai",
      image: flights6,
      description:
        "Experience the hustle and bustle of Mumbai, the economic capital of India, with its thriving film industry, historic landmarks, and diverse culture. Book your flight to Mumbai and explore this vibrant city, with daily direct and connecting flights available from many cities worldwide.",
    },
    {
      heading: "Flights To Singapore",
      image: flights7,
      description:
        "Discover the dynamic city-state of Singapore, with its stunning gardens, mouth-watering cuisine, and modern architecture. Book your flight to Singapore and experience the best of this unique destination, with multiple airlines offering non-stop flights from major cities around the world.",
    },
  ];

  return (
    <>
      <HStack position="relative" w="100%">
        <Swiper
          slidesPerView={(window.innerWidth < 800) ? 1 : 3.5}
          spaceBetween={0}
          //   effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          modules={[EffectCreative, Pagination, Navigation]}
          //   grabCursor={true}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          pagination={{
            clickable: true,
            el: ".swiper-pagination",
            type: "bullets",
            bulletClass: "my-bullet",
            bulletActiveClass: "my-bullet-active",
          }}
          autoplay={{
            delay: 5000, // autoplay each image for 5 seconds
            disableOnInteraction: false, // enable autoplay to continue after user interaction
          }}
          className="mySwiper"
          style={{
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {slides.map((slide, index) => (
            <React.Fragment key={index}>
              <SwiperSlide>
                <HStack py={"1em"} color="white" zIndex="2" px={"0.5em"}>
                  <FlightsCard
                    title={slide.heading}
                    image={slide.image}
                    description={slide.description}
                  />
                </HStack>
              </SwiperSlide>
            </React.Fragment>
          ))}

          <ChakraIcon
            ref={navigationPrevRef}
            name={<ChevronLeftIcon />}
            flag={1}
          />
          <ChakraIcon ref={navigationNextRef} name={<ChevronRightIcon />} />

          <HStack className="swiper-pagination"></HStack>
        </Swiper>
      </HStack>
    </>
  );
};

export default FlightsInfo;
