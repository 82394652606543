import React from 'react'
import {
    Stack,
    VStack,
    Box,
    Heading,
    SimpleGrid,
    Divider,
  } from "@chakra-ui/react";
import ServicesDescription from '../../modules/ServicesDescription';

const PackagesLanding = (props) => {

    const { title, description } = props

    return (
    <>
        <Box  w={'100%'} >
            <Stack  bg="#FFC966" 
                    p={{base: '1em',md: '2em',lg: '3em'}} 
                    align="center" 
                    w={'100%'} 
                    direction={["column", "row"]} 
                    mt={{base: '-4em',md: '-6.2em',lg: '-6.2em'}} 
                    pt={{base: '4em',md: '6em',lg: '5em'}}  >
                <VStack w="100%" p={{base: '0.5em', md: '0.5em', lg:'1em'}} 
                align={'center'}>
                    <Heading
                        fontSize={{ base: "lg", md: "xl", lg: "38" }}
                        fontWeight={700}
                        pt={'0.5em'}
                        fontFamily={'Merriweather'}
                    >
                        {title}
                    </Heading>
                    <Heading
                        fontSize={{ base: "md", md: "lg", lg: "24" }}
                        fontWeight={600}
                        fontFamily={'Merriweather'}
                        color={'#1E2756'}
                        pb={'1em'}
                    >
                        {description}
                    </Heading>
                    <Divider borderWidth="2px" borderColor="#1E2756" />
                    <SimpleGrid 
                    align={'center'} 
                    columns={[2, 3, 3]} 
                    spacing={[5, 5, 5]} >
                        <ServicesDescription title="Holiday Packages" 
                                description="We offer holiday packages that cater to different 
                                themes and interests, such as beach holidays, wildlife safaris, 
                                cultural tours, and more. 
                                Our packages include flights, accommodation, 
                                transfers, tours, and other activities." />
                        <ServicesDescription title="Umrah Packages" 
                                description="We offer Umrah packages that cater to different budgets 
                                and travel styles, including group Umrah packages, VIP Umrah packages, 
                                and customized Umrah packages." />
                        <ServicesDescription title="Luxury Cruise Package" 
                                description="We offer a new generation of cruising that has arrived where 
                                the world-class cruise activities and exotic ports on the planet, 
                                Step off in exciting cities, and experience new cultures. 
                                River cruise or ocean cruise, whether you're a solo traveler, a couple, or 
                                traveling with friends or family, expect to be impressed. 
                                " />
                        <ServicesDescription title="Adventure Packages" 
                                description="We offer adventure packages that cater to adrenaline junkies 
                                and outdoor enthusiasts, such as trekking, hiking, kayaking, and more." />
                        <ServicesDescription title="Leisure Packages" 
                                description="
                                We specialize in both Inbound and Outbound tours, 
                                We facilitate fast and reliable holiday destination 
                                office parties with comfortable pick-up and drop-off at accommodations." />
                    </SimpleGrid>
                </VStack>
            </Stack>
        </Box>
    </>
    )
}

export default PackagesLanding