import React from 'react'
import { VStack, HStack, Box, Image, Heading, OrderedList, ListItem, Stack} from '@chakra-ui/react'
import image from '../assets/Pictures/Privacy Policy 1.png'
import image2 from '../assets/Pictures/Privacy Policy 2.jpg'

const PrivacyAndPolicy = () => {
  return (
    <>
      <VStack overflowX={'hidden'}>
        <Box w={{base: '100%',md: '100%',lg: '80%'}} 
        mb={{base: '-5em',md: '-9em',lg: '-15em'}} zIndex={'2'}>
            <Image
              objectFit="contain"
              src={image}
              alt="privacy and policy"/>
        </Box>
        <Box bg="#FFC966" 
        color="black" w="100%"
        p={'2em'} align={'left'} >
            <Heading  pt={'7em'}
                      pb={'0.5em'}
                      fontSize={{ base: "lg", md: "xl", lg: "32", xl : "40" }} fontFamily={'Merriweather'}
                      fontWeight={500}>Privacy Policies</Heading>
            <Heading
                      py={'1em'}
                      noOfLines={5}
                      color={'#1E2756'}
                      fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }}fontFamily={'Merriweather'}
                      fontWeight={400}>SAS Travel Agency ("SAS," "we," "us," or "our") is committed to protecting 
                      the privacy of our customers and website visitors. This Privacy Policy outlines how we 
                      collect, use, and protect the personal information that we collect from you.</Heading>
            <Heading 
                py={'0.5em'}
                fontSize={{ base: "lg", md: "xl", lg: "28px", xl : "36px" }} fontFamily={'Merriweather'}
                fontWeight={500}>Information We Collect</Heading>
            <Heading 
                  py={'1em'}
                  noOfLines={5}
                  // h="120px"
                  color={'#1E2756'}
                  fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }} fontFamily={'Merriweather'}
                  fontWeight={400}>When you use our website, make a reservation, or contact us, we may 
                  collect certain information from you. This information may include your name, email address, 
                  phone number, billing information, and other personal information.
                  If you subscribe to our newsletter from our website, we also store your email address. 
                  We delete your email address once you delete your user account or if you unsubscribe 
                  from our newsletter.</Heading>


              <Stack w={'100%'} align={'left'} pt={'1em'} direction={[ 'column', 'row']}>
                <VStack w={{base: '100%', md: '100%', lg: '60%'}} align={'left'}>
                  <Heading
                      py={'1em'}
                      fontSize={{ base: "lg", md: "xl", lg: "28px", xl : "36px" }}
                      fontWeight={500}
                      fontFamily={'Merriweather'}>How We Use Your Information</Heading>
                      <OrderedList px={'2em'} color={'#1E2756'}>
                        <ListItem py={'1em'} fontFamily={'Merriweather'} fontWeight={500}
                        fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }}>
                        Provide travel services: We may use your personal information to process your reservations,
                         manage your travel itinerary, and provide other travel-related services.</ListItem>
                        <ListItem py={'1em'} fontFamily={'Merriweather'} 
                        fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }}
                        fontWeight={500} 
                        color={'#1E2756'}>
                        Communicate with you: We may use your personal information to communicate with you about 
                        your travel plans, send you travel-related information and updates, and respond to your inquiries.</ListItem>
                        <ListItem py={'1em'} fontFamily={'Merriweather'} 
                        fontWeight={500} 
                        color={'#1E2756'}
                        fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }}>
                        Improve our services: We may use your personal information to improve our services, 
                        customize your experience on our website, and gather feedback from our customers.</ListItem>
                        <ListItem py={'1em'} fontFamily={'Merriweather'} 
                        fontWeight={500} 
                        color={'#1E2756'}
                        fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }}>
                        Marketing: We may use your personal information to send you marketing communications about our services, promotions, and other 
                        travel-related offers. You may opt-out of these communications at any time.</ListItem>
                      </OrderedList>
                    <Heading 
                    py={'0.5em'}
                    fontSize={{ base: "lg", md: "xl", lg: "28px", xl : "36px" }} fontFamily={'Merriweather'}
                    fontWeight={500}>How We Protect Your Information</Heading>
                    <Heading 
                          py={'1em'}
                          noOfLines={5}
                          fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }}
                          fontWeight={500} 
                          color={'#1E2756'}
                          fontFamily={'Merriweather'}
                          >We take the security of your personal information seriously and have 
                          implemented technical and organizational measures to protect your information 
                          from unauthorized access, use, or disclosure. </Heading>
                    <Heading 
                          py={'0.5em'}
                          fontSize={{ base: "lg", md: "xl", lg: "28px", xl : "36px" }} fontFamily={'Merriweather'}
                          fontWeight={500}>Changes to Our Privacy Policy</Heading>
                    <Heading 
                          py={'1em'}
                          noOfLines={5}
                          // h="120px"
                          fontWeight={500} 
                          color={'#1E2756'}
                          fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }} fontFamily={'Merriweather'}
                          >We reserve the right to update or modify this Privacy Policy at any time without prior notice. We will post the updated 
                          Privacy Policy on our website and notify you of any material changes.</Heading>
                <Heading 
                    py={'0.5em'}
                    fontSize={{base: 'sm',md: 'md',lg: '28px'}} fontFamily={'Merriweather'}
                    fontWeight={500}>Contact Us</Heading>
                <Heading 
                      py={'1em'}
                      noOfLines={5}
                      fontWeight={500} 
                      color={'#1E2756'}
                      fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }}
                      fontFamily={'Merriweather'}>If you have any questions or concerns about our Privacy Policy, 
                      please contact us at info@sas-travel.com or click the Button for more.</Heading> 
                </VStack>
                <HStack w={{base: '100%', md: '100%', lg: '40%', xl: "40%"}} 
                p={{base: '2em', md: '2em', lg: '6em', xl: "8em"}}>
                  <Image
                      objectFit="contain"
                      src={image2}
                      alt="Directions"/>
                </HStack>
              </Stack>    
                     
        </Box>
      </VStack>
    </>
  )
}

export default PrivacyAndPolicy