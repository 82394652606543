import React from "react";
import GoogleMapReact from 'google-map-react';
import { VStack, Heading } from '@chakra-ui/react'
import { MdLocationOn } from "react-icons/md";

const Marker = () => (
  <VStack>
       <MdLocationOn fontSize="42px" color={'#CB0808'} align={'center'} />
  </VStack>
);

export default function CompanyGoogleMap(){
  const defaultProps = {
    center: {
      lat: 25.26132,
      lng: 55.29801
    },
    zoom: 17
  };

  const mapStyles = {
    width: '100%',
    height: '100%'
  };

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyB5unq_9mnOXkMUyLU3icffPjsnRWB53WE" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{ styles: mapStyles }}
      >
        <VStack 
           lat={25.26132}
           lng={55.29801}>
             <Marker/>
            <Heading fontFamily={'Rubik'}
                py={'0.2em'}
                align={'center'}
                color="black"
                fontSize={{base: 'sm',md: 'md',lg: '30px'}} 
                fontWeight={500}>
                    SAS Travels
            </Heading>
        </VStack>
       
        
      </GoogleMapReact>
    </div>
  );
}
