import React from 'react'
import InfoDivider from '../components/templates/Homepage/InfoDivider'
import { VStack, HStack, 
  Image, Heading , UnorderedList, 
  OrderedList, ListItem, 
  Stack, Box} from '@chakra-ui/react'
import about from '../assets/Pictures/About1.jpeg'
import { Player } from '@lottiefiles/react-lottie-player';
import LottieMap from '../assets/Lottie/Map.json';

const About = () => {
  return (
    <>
      <VStack overflowX={'hidden'} 
      bg="#1E2756" 
        color="white" w="100%"
        align={'left'} >
          <VStack w="100%" 
          zIndex={'2'}>
              <Image 
              objectFit="contain"
              src={about}
              alt="product"/>
          </VStack>
          <Box p={'2em'}>
            <Heading  pt={'0.5em'}
                      pb={'0.5em'}
                      fontSize={{ base: "lg", md: "xl", lg: "28px", xl : "36px" }} fontFamily={'Merriweather'}
                      fontWeight={500}>About us</Heading>
            <Heading
                      py={'1em'}
                      noOfLines={8}
                      fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }} fontFamily={'Merriweather'}
                      fontWeight={400}>SAS is a complete destination management company. 
              As one of the premier travel agents, we provide comprehensive 
              travel solutions for leisure, group, corporate or educational purpose. 
              Since our beginning in 2022, we have been growing rapidly and in the 
              process innovating new and flexible ideas.
              we have successfully extended top services to our prestigious customers.</Heading>
              <VStack align={'left'} w="100%">
                <UnorderedList px={'1em'}>
                  <ListItem fontWeight={400} fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }} 
                  fontFamily={'Merriweather'}
                  >Our travel counselors are trained to understand the needs 
                  and desires of the guests and are updated with the latest travel trends </ListItem>
                </UnorderedList>
              </VStack>

              <Stack w={'100%'} align={'left'} pt={'1em'} direction={[ 'column', 'row']}>
                <VStack w={{base: '100%', md: '50%', lg: '50%'}} align={'left'}>
                  <Heading 
                          py={'0.5em'}
                          fontSize={{base: 'sm',md: 'md',lg: '28px'}} fontFamily={'Merriweather'}
                          fontWeight={500}>Our Mission</Heading>
                  <Heading 
                        py={'1em'}
                        noOfLines={5}
                        // h="120px"
                        fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }} fontFamily={'Merriweather'}
                        fontWeight={300}>We believe that Success is measured by the number of 
                        satisfied customers and that is always been our endeavor.</Heading>
                  <OrderedList px={'2em'}>
                    <ListItem py={'1em'} fontFamily={'Merriweather'} 
                   fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }}>
                      Our travel counselors are trained to understand the needs and 
                      desires of the guests and are updated with the latest travel trends </ListItem>
                    <ListItem py={'1em'} fontFamily={'Merriweather'} 
                   fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }} >
                      Our customer-centric approach will be the cornerstone of our success, 
                      as we aim to provide unparalleled service to each and every one of our clients.</ListItem>
                  </OrderedList>
                  <Heading
                      py={'1em'}
                      fontSize={{base: 'sm',md: 'md',lg: '28px'}} 
                      fontWeight={500}
                      fontFamily={'Merriweather'}>Our Vision</Heading>
                    <Heading
                            py={'1em'}
                            noOfLines={5}
                            fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }} 
                            fontWeight={300}
                            fontFamily={'Merriweather'}
                            >
                              Our ultimate goal is to help travelers explore the world in a way that is meaningful, 
                              authentic, and unforgettable. Whether it's a once-in-a-lifetime trip or a quick 
                              getaway, we want our clients to feel cared for, supported, and inspired.
                    </Heading>
                </VStack>

                <HStack w={{base: '100%', md: '50%', lg: '50%'}} p={'2em'}>
                  <Player autoplay
                                  controls
                                  loop
                                  mode="normal"
                                  src={LottieMap}>
                          </Player>
                </HStack>
            </Stack>
          </Box>
              
        </VStack>
      <InfoDivider />
    </>
  )
}

export default About