import React from 'react'
import {
    Stack,
    VStack,
    Box,
    Heading,
    SimpleGrid,
    Divider,
  } from "@chakra-ui/react";
import ServicesDescription from '../../modules/ServicesDescription';

const VisaLanding = (props) => {

    const { title, description } = props

    return (
    <>
        <Box  w={'100%'} >
            <Stack  bg="#FFC966" 
                    p={{base: '1em',md: '2em',lg: '3em'}} 
                    align="center" 
                    w={'100%'} 
                    direction={["column", "row"]} 
                    mt={{base: '-4em',md: '-6.2em',lg: '-6.2em'}} 
                    pt={{base: '4em',md: '6em',lg: '5em'}} >
                <VStack w="100%" p="1em" align={'center'}>
                <Heading
                        fontSize={{ base: "lg", md: "xl", lg: "38" }}
                        fontWeight={700}
                        pt={'0.5em'}
                        fontFamily={'Merriweather'}
                    >
                        {title}
                    </Heading>
                    <Heading
                        fontSize={{ base: "md", md: "lg", lg: "24" }}
                        fontWeight={600}
                        fontFamily={'Merriweather'}
                        color={'#1E2756'}
                        pb={'1em'}
                    >
                        {description}
                    </Heading>
                    <Divider borderWidth="2px" borderColor="#1E2756" />
                    <SimpleGrid align={'center'} columns={[2, 3, 3]} spacing={[3, 5, 5]} >
                        <ServicesDescription title="Visa Consultation" 
                                description="Our visa experts will provide you with a detailed consultation 
                                to help you determine the visa requirements for your travel destination." />
                        <ServicesDescription title="Visa Application Assistance" 
                                description="We will assist you with the visa application process, 
                                ensuring that your application is complete and error-free." />
                        <ServicesDescription title="Documentation and Verification" 
                                description="We will collect all the necessary documents and verify 
                                them for accuracy and completeness.
                                " />
                        <ServicesDescription title="Appointment Booking" 
                                description="We will schedule your visa appointment and guide you through the process." />
                        <ServicesDescription title="Follow-up and Status Updates" 
                                description="We will keep you updated on the status of your visa application 
                                and follow up with the consulate on your behalf.." />
                    </SimpleGrid>
                </VStack>
            </Stack>
        </Box>
    </>
    )
}

export default VisaLanding