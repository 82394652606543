import React from 'react'
import { Stack, VStack, Heading, Box, Image, Divider, HStack, Button} from '@chakra-ui/react'
import { MdOutlineLocationOn, MdCall, MdEmail } from "react-icons/md";
import image from "../assets/Pictures/Contact1.jpg"
import CompanyGoogleMap from '../components/elements/Map'
import { FaWhatsapp } from "react-icons/fa"

const Contact = () => {

const WhatsappButtonClicked = (event) => {

        const whatsappMessage = `Hey, My name is ________.
I saw your website and wanted to get in touch with you about _______________.`;
        const whatsappUrl = `https://wa.me/+971564117736/?text=${encodeURIComponent(whatsappMessage)}`;
        window.open(whatsappUrl, "_blank");
        }

  return (
    <Box overflowX={'hidden'}>
      <Box w="100%"
      h={"100%"} 
      >
            <Image
              objectFit="contain"
              src={image}
              alt="Banner"/>
        </Box>

        <HStack justifyContent={'space-between'} 
                px={{base: '1.5em',md: '3em',lg: '4em'}}  py={'2em'} color="white">
                <Heading color="#1F2744" fontFamily={'Merriweather'}
                fontSize={{base: '24px',md: '30px',lg: '42px'}} >
                Want to get in touch with us?
                </Heading>
                <Button 
                        id="whatsapp" 
                        type="submit" 
                        bg="#1E2756" 
                        color="#FFC966"
                        w={{base: '200px',md: '150px',lg: '150px'}} 
                        h="60px"
                        onClick={WhatsappButtonClicked}
                        variant="solid"
                        > 
                        <Heading fontFamily={'Merriweather'}
                                fontSize={{base: 'sm',md: 'lg',lg: '24px'}} >
                                <FaWhatsapp style={{ fontSize: '22px'}}/>              
                        </Heading>
                        &nbsp; Whatsapp  
                </Button>
                
        </HStack>

        <Stack justifyContent="center" direction={[ 'column', 'row']}
                p="2em" color="white" gap="1em">
          <VStack w={{base: '100%', md: '33.3%', lg: '30%'}} 
                  h={{base: '12em', md: '15em', lg: '20em'}}
                  bg="#FFC966" 
                  gap={'1em'}
                  color="#1F2744"
                  p={{base: '1em', md: '1em', lg: '2em'}}
                  borderRadius={'md'}
                  >
            
            <Heading fontSize={{base: "lg", md: "xl", lg: "2xl"}} 
                    fontFamily={'Merriweather'}
                    pt="0.5em"
                    fontWeigth="600px">VISIT US</Heading>
            <Heading fontSize={{base: "md", md: "lg", lg: "2xl"}} pt="1em" 
                    fontFamily={'Merriweather'}
                    h="130px"
                    fontWeigth="400" align="center">
                    S-26, AL FAHIDI PLAZA <br></br>
                    P.O BOX: 444-891<br></br>
                    BUR DUBAI, DUBAI-U.A.E</Heading>
            <MdOutlineLocationOn fontSize="26px"/>
            
          </VStack>
          <VStack w={{base: '100%', md: '33.3%', lg: '30%'}} 
                  h={{base: '12em', md: '15em', lg: '20em'}}
                  bg="#D45F46" 
                  gap={'1em'}
                  color="#FFFFFF"
                  p={{base: '1em', md: '1em', lg: '2em'}}
                  borderRadius={'md'}>
            
            <Heading fontSize={{base: "lg", md: "xl", lg: "2xl"}} 
                    fontFamily={'Merriweather'}
                    pt="0.5em"
                    fontWeigth="600px">CALL US</Heading>
            <Heading fontSize={{base: "md", md: "lg", lg: "2xl"}} pt="1em" 
                    fontFamily={'Merriweather'}
                    h="130px"
                    fontWeigth="400" align="center">
                      +971 4343-4422<br></br>
                    +971 56411-7735<br></br>
                    +971 56411-7736</Heading>
            <MdCall fontSize="26px"/>
          </VStack>
          <VStack w={{base: '100%', md: '33.3%', lg: '30%'}} 
                  h={{base: '12em', md: '15em', lg: '20em'}}
                  gap={'1em'}
                  bg="#FFC966" 
                  p={{base: '1em', md: '1em', lg: '2em'}}
                  color="#1F2744"
                  borderRadius={'md'}>
            
            <Heading fontSize={{base: "lg", md: "xl", lg: "2xl"}} 
                    fontFamily={'Merriweather'}
                    pt="0.5em"
                    fontWeigth="600px">EMAIL US</Heading>
            <Heading fontSize={{base: "md", md: "lg", lg: "2xl"}} pt="1em" 
                    fontFamily={'Merriweather'}
                    h="130px"
                    fontWeigth="400" align="center">
                      Info@sas-travels.com<br></br>
                    sales@sas-travels.com</Heading>
            <MdEmail fontSize="26px"/>
          </VStack>
        </Stack>
        
        <Box justifyContent="center"  
        px={{base: '1.5em', md: '3em', lg: '5em'}} py={'2em'}  
        position={'relative'}
        >

          <Divider borderWidth="2px" borderColor="#1E2756"/>
                <Heading fontSize={{base: "xl", md: "2xl", lg: "3xl"}} 
                    py="1em"
                    align="center"
                    fontWeigth="650px">Map</Heading>
                        {/* <div className="google-map"> */}
                <CompanyGoogleMap />  
        </Box>
      {/* </Container> */}
    </Box>
  )
}
export default Contact