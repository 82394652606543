import React from 'react'
import { 
    Box,
    Link,
    Heading,
    Image, 
} from '@chakra-ui/react';

const ServicesBox = (props) => {
    const { image, title, link, flag } = props;

    console.log({flag})
    return (
        <Box
            bg={flag === "active" ? "#D45F46" : "#1E2756"}
            w={{base: '78px',md: '130px',lg: '160px'}} 
            h={{base: '100px',md: '130px',lg: '140px'}} 
            align={'center'} 
            p={{base: '0.3em',md: '0.3em',lg: '0.4em'}} 
            borderRadius={{base: '2px',md: '10px',lg: '10px'}} 
            boxShadow={'md'}
            mx={{base: '0',md: '0',lg: '1em'}} 
            zIndex={2}
            >
            <Image p={{base: '0.3em',md: '0',lg: '0'}} 
                objectFit="contain"
                borderRadius="md"
                src={image}
                alt="product"
            />
            <Link href={link}>
                <Heading 
                fontFamily={'Merriweather'}
                color={'#FFC966'}
                fontSize={{base: 'sm',md: 'lg',lg: '24px'}} 
                fontWeight={600}
                >
                {title}
                </Heading>
            </Link>
        </Box>          
    )
}

export default ServicesBox