import React from "react";
import {
  Center,
  Container,
  Stack,
  VStack,
  HStack,
  Heading,
} from "@chakra-ui/react";

const HomeHighlights = () => {
  return (
    <Center p={{ base: "0.25em", md: "1.5em", lg: "3em" }}>
    <Container maxW="container.xl">
      <VStack>
        <Heading
          fontSize={{ base: "lg", md: "2xl", lg: "42" }}
          fontWeight={700}
          fontFamily={'Merriweather'}
          ml={5}
        >
          Highlights
        </Heading>
        <Stack p={"2em"} align="center" direction={["column", "row"]}>
          <HStack>
            
          </HStack>
        </Stack>
      </VStack>
    </Container>
  </Center>
  )
}

export default HomeHighlights