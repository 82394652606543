import React from 'react'
import { MdLocationOn, MdOutlineConnectingAirports } from "react-icons/md";
import { SiContactlesspayment } from "react-icons/si";
import image2 from '../../../assets/Pictures/Packages/Extra.jpg'
import { VStack, Heading, Box, Stack, Image} from '@chakra-ui/react'

const PackagesExtra = () => {
  return (
    // <Box>
    <Box bg="#FFC966"
    pt={'1em'} 
    w={'100%'}>
              <Heading
               fontFamily={'Merriweather'}
                color="#1E2756"
                pt="0.5em"
                align={'center'}
                fontSize={{base: '32px',md: '40px',lg: '40px'}} 
                fontWeight={600}>
                Book Your next trip<br></br>
                  in 3 easy steps
              </Heading>
        <Stack px={"3em"}
         align="center" 
         direction={["column", "row"]}>
            <VStack 
            w={{base: '100%',md: '100%',lg: '50%', xl: '60%'}}
            p={{base: '1em',md: '2em',lg: '3em'}}
            align={'left'}>
              <Stack py={'0.5em'} align={'center'} 
              gap={{ base: "0.75em", md: "1em", lg: "1em", xl : "1.5em" }}
              direction={["column", "row"]} >
                {/* <Box w={'15%'}> */}
                  <MdLocationOn fontSize="70px" color={'#1E2756'}  />
                {/* </Box> */}
              
                <VStack w={'85%'}>
                  <Heading
                   fontSize={{ base: "lg", md: "xl", lg: "28px", xl : "36px" }}
                    fontWeight={600}
                    color="#1E2756"
                    fontFamily={'Merriweather'}
                  >
                    Choose Destination
                  </Heading>
                  <Heading
                    fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }}
                    fontWeight={400}
                    color="white"
                    fontFamily={'Merriweather'}
                  >
                    Our platform allows you to browse a wide range of exciting travel destinations 
                    from all over the world. With detailed information on each location, 
                    including things to do and see,  
                    you can easily pick the perfect place for your next adventure.
                  </Heading>
                </VStack>

              </Stack>
              
              <Stack py={'0.5em'} align={'center'} 
              gap={'1em'} 
              direction={["column", "row"]}>
                {/* <Box w={'15%'}> */}
                <SiContactlesspayment fontSize="70px"
                 color={'#1E2756'}  />
              {/* </Box> */}
                <VStack w={'85%'}>
                  <Heading
                   fontSize={{ base: "lg", md: "xl", lg: "28px", xl : "36px" }}
                    fontWeight={600}
                    color="#1E2756"
                    fontFamily={'Merriweather'}
                  >
                    Make Payment
                  </Heading>
                  <Heading
                    fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }}
                    fontWeight={400}
                    color="white"
                    fontFamily={'Merriweather'}
                  >
                    Once you've selected your destination, our easy and secure payment system 
                    makes it simple to complete your booking. With a variety of payment options 
                    available, including credit card and bank transfer, you can choose the 
                    method that works best for you.
                  </Heading>
                </VStack>

              </Stack>

              <Stack py={'0.5em'} align={'center'} 
              gap={'1em'} 
              direction={["column", "row"]}>
                <MdOutlineConnectingAirports fontSize="70px"
                  color={'#1E2756'}  />
                <VStack w={'85%'}>
                  <Heading
                   fontSize={{ base: "lg", md: "xl", lg: "28px", xl : "36px" }}
                    fontWeight={600}
                    color="#1E2756"
                    fontFamily={'Merriweather'}
                  >
                    Reach Airport on Selected Date
                  </Heading>
                  <Heading
                    fontSize={{ base: "lg", md: "lg", lg: "20px", xl : "24px" }}
                    fontWeight={400}
                    color="white"
                    fontFamily={'Merriweather'}
                  >
                    With your booking confirmed, all that's left is to make your way to the 
                    airport on the day of your trip. Our platform provides you with all the 
                    information you need, including the exact location and time of your departure, 
                    so you can arrive with plenty of time to spare and start your journey stress-free.
                  </Heading>
                </VStack>

              </Stack>
            </VStack>

            <VStack w={{base: '100%',md: '100%',lg: '50%', xl: '40%'}} 
            p={{base: '1em',md: '1em',lg: '5em', xl: "10em"}}>
            <Box>
                <Image
                  objectFit="contain"
                  borderRadius="md"
                  src={image2}
                  alt="picture"/>               
            </Box>
            </VStack>
        </Stack>
      </Box>
  )
}

export default PackagesExtra