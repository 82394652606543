import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { HStack, VStack, Image, Heading } from "@chakra-ui/react";
import SwiperCore, { EffectCreative, Pagination, Navigation, Autoplay } from "swiper/core";
import ChakraIcon from "../../elements/ChakraIcon";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-flip";
import "swiper/css/navigation";
import "../../../theme/index.css"

import land1 from "../../../assets/Pictures/Homepage/Land1.jpg";
import land2 from "../../../assets/Pictures/Homepage/Land2.jpg";
import land3 from "../../../assets/Pictures/Homepage/Land3.jpg";
import land4 from "../../../assets/Pictures/Homepage/Land4.jpg";
import land5 from "../../../assets/Pictures/Homepage/Land5.jpg";
import land6 from "../../../assets/Pictures/Homepage/Land6.jpg";

SwiperCore.use([Pagination]);
SwiperCore.use([Navigation]);
SwiperCore.use([Autoplay]);


const Hero = (props) => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const slides = [
    // The image ratio should be 3:1
    {
      heading: "Enjoy your Dream vacation in Dubai",
      image: land1
    },
    {
      heading: "Discover The Beauty of Alps Mountain Ranges",
      image: land2
    },
    {
      heading: "Experience The Wonders of South Asian-Pacific Countries",
      image: land3
    },
    {
      heading: "Explore The Majesty of Europe",
      image: land4
    },
    {
      heading: "Capture The Greatness of Grand Canyon",
      image: land5
    },
    {
      heading: "A symbol of India's rich heritage",
      image: land6
    }
  ];
  

  return (
    <>
      <HStack position="relative" w="100%">
        <Swiper
          spaceBetween={40}
          effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          modules={[EffectCreative, Pagination, Navigation]}
          lazy={true}
          // grabCursor={true}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          pagination={{
            clickable: true,
            el: ".swiper-pagination",
            type: "bullets",
            bulletClass: "my-bullet",
            bulletActiveClass: "my-bullet-active",
          }}
          autoplay={{
            delay: 5000, // autoplay each image for 5 seconds
            disableOnInteraction: false, // enable autoplay to continue after user interaction
          }}
          className="mySwiper"
          style={{
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          {slides.map((slide, index) => (
            <React.Fragment key={index}>
              <SwiperSlide>
              <VStack
                top={{ base: "60%", md: "70%", lg: "70%" }}
                py={{ base: "0", md: "0.25em", lg: "1em" }}
                w="100%"
                h="auto"
                color="white"
                zIndex="2"
                position="absolute"
              >
                <Heading
                  fontSize={{ base: "md", md: "lg", lg: "40px" }}
                  fontFamily="Merriweather"
                  color="#FFFFFF" 
                  px={{ base: "0em", md: "0.5em", lg: "1em" }}  
                  py={{ base: "0", md: "0.25em", lg: "0.5em" }}
                  bg="rgba(30, 39, 86, 0.5)"
                >
                  {slide.heading}
                </Heading>
                <br>
                </br>
              </VStack>
                <Image objectFit="contain" src={slide.image} w={'100%'}
                alt={slide.heading} 
                onLoad={() => {window.dispatchEvent(new Event('resize'));}}/>
              </SwiperSlide>
            </React.Fragment>
          ))}

          <ChakraIcon
            ref={navigationPrevRef}
            name={<ChevronLeftIcon />}
            flag={1}
          />
          <ChakraIcon ref={navigationNextRef} 
          name={<ChevronRightIcon />} />

          <div className="swiper-pagination"></div>
        </Swiper>
      </HStack>
    </>
  );
};

export default Hero;

