import React from "react";
import {
  SimpleGrid,
  VStack,
  HStack,
  Heading,
} from "@chakra-ui/react";
import HomeServicesCard from "../../modules/HomeServicesCard";
import services1 from "../../../assets/Pictures/Homepage/services1.jpg";
import services2 from "../../../assets/Pictures/Homepage/services2.jpg";
import services3 from "../../../assets/Pictures/Homepage/services3.jpg";
import services4 from "../../../assets/Pictures/Homepage/services4.jpg";


const HomeServices = () => {
  return (
        <VStack p={{ base: "0.5em", md: "1.5em", lg: "3em" }}
            align={'center'}>
          <Heading
            fontSize={{ base: "xl", md: "2xl", lg: "42" }}
            fontWeight={700}
            fontFamily={'Merriweather'}
            ml={5}
          >
            Our Services
          </Heading>
          <SimpleGrid p={{ base: "0", md: "1em", lg: "2em" }}  
          spacing='10px'
          align="center" columns={[1, 1, 2]}>
            <HStack>
              <HomeServicesCard
                image={services1}
                title="Flights"
                link="/services/flights"
                description="We understand that booking flights can be a 
                        time-consuming and daunting task. That's why we offer a range of flight services 
                        to make your travel experience hassle-free."
              />

              <HomeServicesCard
                image={services3}
                title="Hotel"
                link="/services/hotels"
                description="We understand that finding the perfect accommodation 
                        is essential to a comfortable and enjoyable travel experience. 
                        That's why we offer a comprehensive range of hotel services to cater to 
                        different preferences and budgets."
              />
              </HStack>
              <HStack>
              <HomeServicesCard
                image={services2}
                title="Visa"
                link="/services/visa"
                description="We offer visa services to make your international 
                        travel experience hassle-free. Our experienced team will guide you through the visa 
                        application process and ensure that you have all the necessary documentation."
              />

              <HomeServicesCard
                image={services4}
                title="Attractions"
                link="/services/attractions"
                description="We understand that exploring the top attractions 
                        of a destination is an essential part of any travel experience. That's why we offer a 
                        comprehensive range of attraction services to help you make the most of your trip."
              />
              </HStack>
          </SimpleGrid>
        </VStack>
  
  );
};

export default HomeServices;
