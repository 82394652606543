import React from 'react'
import InfoCard from '../../modules/InfoCard'
import { SimpleGrid, HStack, Heading, VStack} from '@chakra-ui/react';
import { MdLocalOffer, MdOutlineThumbsUpDown} from "react-icons/md"
import { GoCreditCard } from "react-icons/go"
import { FiPhoneCall } from "react-icons/fi"

const InfoDivider = () => {
  return (
    <>
        <VStack bg="#FFC966">
            <Heading    fontSize={{base: 'lg',md: 'xl',lg: '30px', xl: '36px'}} 
                        fontWeight={400} 
                        fontFamily={'Merriweather'}
                        color="black"
                        pt="2em"
                        >
                What Makes Our Agency Different
            </Heading> 
            <SimpleGrid p="1em" 
            justify="center" columns={[1, 2, 2]}
            // direction={[ 'column', 'row']}
            >
                <HStack>
                    <InfoCard 
                        icon={<MdLocalOffer/>}
                        title="Best Packages" 
                        description="We offer best packages 
                        which provides utmost comfort."/>
                    <InfoCard 
                        icon={<FiPhoneCall/>}
                        title="Experienced Travel Agents" 
                        description="Our agents are extremely professional and experienced in providing excellent services."/>
                </HStack>
                <HStack>
                <InfoCard 
                    icon={<GoCreditCard/>}
                    title="Unbeatable prices" 
                    description="We offer reasonable prices with discounts like no where."/>
                <InfoCard 
                    icon={<MdOutlineThumbsUpDown/>}
                    title="Our Dedicated support" 
                    description="Our customer service provides constant support for your easiness."/>
                </HStack>
            </SimpleGrid>
        </VStack>
    </>
  )
}

export default InfoDivider